/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import styled from 'styled-components';
import {
  Card as ConstellationCard,
  Spacer,
  Heading,
  Divider,
  spaceMixin,
  List,
  IconBellOutline,
  Text,
  MediaObject,
  Image,
  Paragraph,
} from '@zillow/constellation';
import { Announcements } from '../../components/OfficeAnnouncementsCard/Announcements';
import { isAfter, isBefore } from 'date-fns';
import megaphone from '../../assets/megaphone.png';
export interface OfficeAnnouncementsProps {
    startDate: Date;
    announcement: string;
    expirationDate: Date;
}

const Card = styled(ConstellationCard)`
  box-sizing: border-box;
  height: 100%;
`;

const AnnouncementsDivider = styled(Divider)`
  margin: ${spaceMixin(1.5)} 0;
`;

const PlaceholderImage = styled(Image)`
  height: 90px;
  margin-top: ${spaceMixin(1.5)};
`;

export const OfficeAnnouncementsCard: React.FC = () => {

  const announcements = Announcements;
  const isWithinDateRange = (startDate: Date, endDate: Date) => isAfter(new Date(), startDate) && isBefore(new Date, endDate);
  const getActiveAnnouncements = () => 
    announcements.filter(a => isWithinDateRange(a.startDate, a.expirationDate));

  return (
    <Card cardType="secondary" paddingTop="lg" paddingX="lg">
      <Heading level="4" fontFamily="serif" marginBottom="xs">
        Announcements 
      </Heading>
      <Spacer margin="sm" />
              {getActiveAnnouncements().length === 0 ? (
                <MediaObject
                media={
                  <PlaceholderImage alt={'megaphone image'} title={'megaphone'} src={megaphone} />
                }
                direction="column"
              >
                <Heading level="5">No active announcements</Heading>
                <Spacer margin="xs" />
                <Paragraph>
                Check back later! Office updates and other office-visit related announcements will 
                be posted here. 
                </Paragraph>
              </MediaObject>
              ) : (
                <List appearance="none">
                  {getActiveAnnouncements().map((item) => (
                      <List.Item key={item.startDate.toISOString()}>
                        <IconBellOutline size="sm" marginRight="xs" fontColor="brand"/>
                        <Text>{item.announcement}</Text>
                        <AnnouncementsDivider />
                      </List.Item>
                  ))}
                </List>
              )}
    </Card>
  );
};
