/* eslint-disable */
import Long from 'long';
import _m0 from 'protobufjs/minimal';
import {
  StatusType,
  statusTypeFromJSON,
  statusTypeToJSON,
} from './gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import {
  MassUploadReportStatus,
  MassUploadReport,
  massUploadReportStatusFromJSON,
  massUploadReportStatusToJSON,
} from './hqengine_mass_upload';
import {
  CompanyEventType,
  CompanyEventUserStatusType,
  AcknowledgeCompanyEventResponseType,
  CompanyEvent,
  CompanyEventUser,
  EventLocation,
  companyEventTypeFromJSON,
  companyEventTypeToJSON,
  companyEventUserStatusTypeFromJSON,
  companyEventUserStatusTypeToJSON,
  acknowledgeCompanyEventResponseTypeFromJSON,
  acknowledgeCompanyEventResponseTypeToJSON,
} from './hqengine_company_event';
import { Timestamp } from './google/protobuf/timestamp';
import { User, EmployeeFound } from './hqengine_user';
import { Office } from './hqengine_office';
import { Floor } from './hqengine_floor';
import {
  OfficeUsage,
  OfficeVisit,
  BookingOfficeVisit,
  RejectedBooking,
} from './hqengine_office_visit';
import { ServiceStatus } from './gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/service_status';
import { UserInboxMessage } from './hqengine_user_inbox_message';
import { StringValue, Int64Value } from './google/protobuf/wrappers';

export const protobufPackage = 'peng_hqengine_contract';

export interface AuthResponse {
  status: StatusType;
  message: string;
  user: User | undefined;
  impersonator: User | undefined;
}

/** GetUserInfo */
export interface GetUserInfoRequest {}

export interface GetUserInfoResponse {
  status: StatusType;
  message: string;
  user: User | undefined;
  /** @deprecated */
  isImpersonationAllowed: boolean;
  permissions: GetUserInfoResponse_Permissions | undefined;
  impersonator: GetUserInfoResponse_Impersonator | undefined;
}

export interface GetUserInfoResponse_Permissions {
  canImpersonate: boolean;
  canMassUpload: boolean;
  canPlanCompanyEvent: boolean;
}

export interface GetUserInfoResponse_Impersonator {
  user: User | undefined;
}

/** GetMyImpersonatees */
export interface GetMyImpersonateesRequest {}

export interface GetMyImpersonateesResponse {
  status: StatusType;
  message: string;
  isAllowedAll: boolean;
  impersonatees: EmployeeFound[];
}

/** GetAllOffices */
export interface GetAllOfficesRequest {}

export interface GetAllOfficesResponse {
  status: StatusType;
  message: string;
  offices: { [key: string]: Office };
}

export interface GetAllOfficesResponse_OfficesEntry {
  key: string;
  value: Office | undefined;
}

/** GetAllOfficeFloors */
export interface OfficeFloors {
  floors: Floor[];
}

export interface GetAllOfficeFloorsRequest {}

export interface GetAllOfficeFloorsResponse {
  status: StatusType;
  message: string;
  officeFloors: { [key: string]: OfficeFloors };
}

export interface GetAllOfficeFloorsResponse_OfficeFloorsEntry {
  key: string;
  value: OfficeFloors | undefined;
}

/** GetOfficeBlockedDates */
export interface GetOfficeBlockedDatesRequest {
  officeId: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export interface GetOfficeBlockedDatesResponse {
  status: StatusType;
  message: string;
  dates: Date[];
}

export interface BlockedDates {
  dates: Date[];
}

export interface GetAllOfficesBlockedDatesRequest {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export interface GetAllOfficesBlockedDatesResponse {
  status: StatusType;
  message: string;
  blockedDates: { [key: string]: BlockedDates };
}

export interface GetAllOfficesBlockedDatesResponse_BlockedDatesEntry {
  key: string;
  value: BlockedDates | undefined;
}

/** GetFullyBookedDates */
export interface GetFullyBookedDatesRequest {
  officeId: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export interface GetFullyBookedDatesResponse {
  status: StatusType;
  message: string;
  fullyBookedDates: Date[];
}

/** GetOfficeUsagesRequest */
export interface GetOfficeUsagesRequest {
  officeId: string;
  dates: Date[];
}

/** GetOfficeUsagesResponse */
export interface GetOfficeUsagesResponse {
  status: StatusType;
  message: string;
  officeUsages: OfficeUsage[];
}

/** GetEmployeeVisits */
export interface GetEmployeeVisitsRequest {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export interface GetEmployeeVisitsResponse {
  status: StatusType;
  message: string;
  visits: OfficeVisit[];
}

/** BookOfficeVisit */
export interface BookOfficeVisitRequest {
  visit: BookingOfficeVisit | undefined;
}

export interface BookOfficeVisitResponse {
  status: StatusType;
  message: string;
  bookedVisits: OfficeVisit[];
  rejectedBookings: RejectedBooking[];
}

/** CancelOfficeVisit */
export interface CancelOfficeVisitRequest {
  visitIds: string[];
}

export interface CancelOfficeVisitResponse {
  status: StatusType;
  message: string;
  canceledVisits: OfficeVisit[];
}

/** GetHATTStatus */
export interface GetHATTStatusRequest {}

export interface GetHATTStatusResponse {
  status: StatusType;
  message: string;
  completed: boolean;
}

/** GetVaccinationStatus */
export interface GetVaccinationStatusRequest {}

export interface GetVaccinationStatusResponse {
  status: StatusType;
  message: string;
  approved: boolean;
}

/** GetTrainingStatus */
export interface GetTrainingStatusRequest {}

export interface GetTrainingStatusResponse {
  status: StatusType;
  message: string;
  completed: boolean;
}

/** IsBadgeExempt */
export interface IsBadgeExemptRequest {
  userId: string;
}

export interface IsBadgeExemptResponse {
  status: StatusType;
  message: string;
  isExempt: boolean;
}

/** GetSystemHealth */
export interface GetSystemHealthRequest {}

export interface GetSystemHealthResponse {
  status: StatusType;
  message: string;
  serviceStatuses: ServiceStatus[];
}

/** GetUserInboxMessages */
export interface GetUserInboxMessagesRequest {}

export interface GetUserInboxMessagesResponse {
  status: StatusType;
  message: string;
  inboxMessages: UserInboxMessage[];
}

/** AcknowledgeUserInboxMessage */
export interface AcknowledgeUserInboxMessageRequest {
  inboxMessageId: string;
}

export interface AcknowledgeUserInboxMessageResponse {
  status: StatusType;
  message: string;
}

export interface UploadOfficeVisitsInBulkRequest {
  filename: string;
  file: Uint8Array;
}

export interface UploadOfficeVisitsInBulkResponse {
  status: StatusType;
  message: string;
  massUploadId: string;
  result: MassUploadReportStatus;
}

export interface GetMassUploadReportRequest {
  id: string;
}

export interface GetMassUploadReportResponse {
  status: StatusType;
  message: string;
  report: MassUploadReport | undefined;
}

export interface GetAllMassUploadReportsRequest {}

export interface GetAllMassUploadReportsResponse {
  status: StatusType;
  message: string;
  reports: MassUploadReport[];
}

/** SearchEmployees */
export interface SearchEmployeesRequest {
  keyword: string;
  searchType: SearchEmployeesRequest_SearchEmployeesType;
}

export enum SearchEmployeesRequest_SearchEmployeesType {
  SEARCH_EMPLOYEES_TYPE_UNSPECIFIED = 0,
  SEARCH_EMPLOYEES_TYPE_IMPERSONATION = 1,
  SEARCH_EMPLOYEES_TYPE_PLANNER = 2,
  UNRECOGNIZED = -1,
}

export function searchEmployeesRequest_SearchEmployeesTypeFromJSON(
  object: any,
): SearchEmployeesRequest_SearchEmployeesType {
  switch (object) {
    case 0:
    case 'SEARCH_EMPLOYEES_TYPE_UNSPECIFIED':
      return SearchEmployeesRequest_SearchEmployeesType.SEARCH_EMPLOYEES_TYPE_UNSPECIFIED;
    case 1:
    case 'SEARCH_EMPLOYEES_TYPE_IMPERSONATION':
      return SearchEmployeesRequest_SearchEmployeesType.SEARCH_EMPLOYEES_TYPE_IMPERSONATION;
    case 2:
    case 'SEARCH_EMPLOYEES_TYPE_PLANNER':
      return SearchEmployeesRequest_SearchEmployeesType.SEARCH_EMPLOYEES_TYPE_PLANNER;
    case -1:
    case 'UNRECOGNIZED':
    default:
      return SearchEmployeesRequest_SearchEmployeesType.UNRECOGNIZED;
  }
}

export function searchEmployeesRequest_SearchEmployeesTypeToJSON(
  object: SearchEmployeesRequest_SearchEmployeesType,
): string {
  switch (object) {
    case SearchEmployeesRequest_SearchEmployeesType.SEARCH_EMPLOYEES_TYPE_UNSPECIFIED:
      return 'SEARCH_EMPLOYEES_TYPE_UNSPECIFIED';
    case SearchEmployeesRequest_SearchEmployeesType.SEARCH_EMPLOYEES_TYPE_IMPERSONATION:
      return 'SEARCH_EMPLOYEES_TYPE_IMPERSONATION';
    case SearchEmployeesRequest_SearchEmployeesType.SEARCH_EMPLOYEES_TYPE_PLANNER:
      return 'SEARCH_EMPLOYEES_TYPE_PLANNER';
    default:
      return 'UNKNOWN';
  }
}

export interface SearchEmployeesResponse {
  status: StatusType;
  message: string;
  employees: EmployeeFound[];
}

export interface CreateCompanyEventRequest {
  type: CompanyEventType;
  name: string | undefined;
  purpose: string | undefined;
  comments: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  locationId: string | undefined;
  host: string | undefined;
  planners: string[];
  officeId: string | undefined;
}

export interface CreateCompanyEventResponse {
  status: StatusType;
  message: string;
  eventId: string;
  rejectedVisits: RejectedBooking | undefined;
}

export interface InviteAttendeesForCompanyEventRequest {
  eventId: string;
  attendeeIds: string[];
  message: string | undefined;
}

export interface InviteAttendeesForCompanyEventResponse {
  status: StatusType;
  message: string;
}

export interface GetCompanyEventRequest {
  eventId: string;
}

export interface GetCompanyEventResponse {
  status: StatusType;
  message: string;
  event: CompanyEvent | undefined;
  users: CompanyEventUser[];
}

export interface GetCompanyEventsRequest {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export interface GetCompanyEventsResponse {
  status: StatusType;
  message: string;
  events: CompanyEvent[];
}

export interface CancelCompanyEventRequest {
  eventId: string;
  reason: string | undefined;
}

export interface CancelCompanyEventResponse {
  status: StatusType;
  message: string;
}

export interface AcknowledgeCompanyEventRequest {
  eventId: string;
  status: CompanyEventUserStatusType;
  comments: string | undefined;
  officeId: string | undefined;
}

export interface AcknowledgeCompanyEventResponse {
  status: StatusType;
  message: string;
  resultType: AcknowledgeCompanyEventResponseType;
  rejectedVisits: RejectedBooking | undefined;
}

export interface GetAvailableLocationsRequest {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export interface GetAvailableLocationsResponse {
  status: StatusType;
  message: string;
  locations: EventLocation[];
}

const baseAuthResponse: object = { status: 0, message: '' };

export const AuthResponse = {
  encode(message: AuthResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(26).fork()).ldelim();
    }
    if (message.impersonator !== undefined) {
      User.encode(message.impersonator, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAuthResponse } as AuthResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.user = User.decode(reader, reader.uint32());
          break;
        case 4:
          message.impersonator = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AuthResponse {
    const message = { ...baseAuthResponse } as AuthResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = User.fromJSON(object.user);
    } else {
      message.user = undefined;
    }
    if (object.impersonator !== undefined && object.impersonator !== null) {
      message.impersonator = User.fromJSON(object.impersonator);
    } else {
      message.impersonator = undefined;
    }
    return message;
  },

  toJSON(message: AuthResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.user !== undefined && (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.impersonator !== undefined &&
      (obj.impersonator = message.impersonator ? User.toJSON(message.impersonator) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<AuthResponse>): AuthResponse {
    const message = { ...baseAuthResponse } as AuthResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = User.fromPartial(object.user);
    } else {
      message.user = undefined;
    }
    if (object.impersonator !== undefined && object.impersonator !== null) {
      message.impersonator = User.fromPartial(object.impersonator);
    } else {
      message.impersonator = undefined;
    }
    return message;
  },
};

const baseGetUserInfoRequest: object = {};

export const GetUserInfoRequest = {
  encode(_: GetUserInfoRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserInfoRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetUserInfoRequest } as GetUserInfoRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetUserInfoRequest {
    const message = { ...baseGetUserInfoRequest } as GetUserInfoRequest;
    return message;
  },

  toJSON(_: GetUserInfoRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<GetUserInfoRequest>): GetUserInfoRequest {
    const message = { ...baseGetUserInfoRequest } as GetUserInfoRequest;
    return message;
  },
};

const baseGetUserInfoResponse: object = { status: 0, message: '', isImpersonationAllowed: false };

export const GetUserInfoResponse = {
  encode(message: GetUserInfoResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(26).fork()).ldelim();
    }
    if (message.isImpersonationAllowed === true) {
      writer.uint32(32).bool(message.isImpersonationAllowed);
    }
    if (message.permissions !== undefined) {
      GetUserInfoResponse_Permissions.encode(
        message.permissions,
        writer.uint32(42).fork(),
      ).ldelim();
    }
    if (message.impersonator !== undefined) {
      GetUserInfoResponse_Impersonator.encode(
        message.impersonator,
        writer.uint32(242).fork(),
      ).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserInfoResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetUserInfoResponse } as GetUserInfoResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.user = User.decode(reader, reader.uint32());
          break;
        case 4:
          message.isImpersonationAllowed = reader.bool();
          break;
        case 5:
          message.permissions = GetUserInfoResponse_Permissions.decode(reader, reader.uint32());
          break;
        case 30:
          message.impersonator = GetUserInfoResponse_Impersonator.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetUserInfoResponse {
    const message = { ...baseGetUserInfoResponse } as GetUserInfoResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = User.fromJSON(object.user);
    } else {
      message.user = undefined;
    }
    if (object.isImpersonationAllowed !== undefined && object.isImpersonationAllowed !== null) {
      message.isImpersonationAllowed = Boolean(object.isImpersonationAllowed);
    } else {
      message.isImpersonationAllowed = false;
    }
    if (object.permissions !== undefined && object.permissions !== null) {
      message.permissions = GetUserInfoResponse_Permissions.fromJSON(object.permissions);
    } else {
      message.permissions = undefined;
    }
    if (object.impersonator !== undefined && object.impersonator !== null) {
      message.impersonator = GetUserInfoResponse_Impersonator.fromJSON(object.impersonator);
    } else {
      message.impersonator = undefined;
    }
    return message;
  },

  toJSON(message: GetUserInfoResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.user !== undefined && (obj.user = message.user ? User.toJSON(message.user) : undefined);
    message.isImpersonationAllowed !== undefined &&
      (obj.isImpersonationAllowed = message.isImpersonationAllowed);
    message.permissions !== undefined &&
      (obj.permissions = message.permissions
        ? GetUserInfoResponse_Permissions.toJSON(message.permissions)
        : undefined);
    message.impersonator !== undefined &&
      (obj.impersonator = message.impersonator
        ? GetUserInfoResponse_Impersonator.toJSON(message.impersonator)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<GetUserInfoResponse>): GetUserInfoResponse {
    const message = { ...baseGetUserInfoResponse } as GetUserInfoResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.user !== undefined && object.user !== null) {
      message.user = User.fromPartial(object.user);
    } else {
      message.user = undefined;
    }
    if (object.isImpersonationAllowed !== undefined && object.isImpersonationAllowed !== null) {
      message.isImpersonationAllowed = object.isImpersonationAllowed;
    } else {
      message.isImpersonationAllowed = false;
    }
    if (object.permissions !== undefined && object.permissions !== null) {
      message.permissions = GetUserInfoResponse_Permissions.fromPartial(object.permissions);
    } else {
      message.permissions = undefined;
    }
    if (object.impersonator !== undefined && object.impersonator !== null) {
      message.impersonator = GetUserInfoResponse_Impersonator.fromPartial(object.impersonator);
    } else {
      message.impersonator = undefined;
    }
    return message;
  },
};

const baseGetUserInfoResponse_Permissions: object = {
  canImpersonate: false,
  canMassUpload: false,
  canPlanCompanyEvent: false,
};

export const GetUserInfoResponse_Permissions = {
  encode(
    message: GetUserInfoResponse_Permissions,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.canImpersonate === true) {
      writer.uint32(8).bool(message.canImpersonate);
    }
    if (message.canMassUpload === true) {
      writer.uint32(16).bool(message.canMassUpload);
    }
    if (message.canPlanCompanyEvent === true) {
      writer.uint32(24).bool(message.canPlanCompanyEvent);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserInfoResponse_Permissions {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetUserInfoResponse_Permissions } as GetUserInfoResponse_Permissions;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.canImpersonate = reader.bool();
          break;
        case 2:
          message.canMassUpload = reader.bool();
          break;
        case 3:
          message.canPlanCompanyEvent = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetUserInfoResponse_Permissions {
    const message = { ...baseGetUserInfoResponse_Permissions } as GetUserInfoResponse_Permissions;
    if (object.canImpersonate !== undefined && object.canImpersonate !== null) {
      message.canImpersonate = Boolean(object.canImpersonate);
    } else {
      message.canImpersonate = false;
    }
    if (object.canMassUpload !== undefined && object.canMassUpload !== null) {
      message.canMassUpload = Boolean(object.canMassUpload);
    } else {
      message.canMassUpload = false;
    }
    if (object.canPlanCompanyEvent !== undefined && object.canPlanCompanyEvent !== null) {
      message.canPlanCompanyEvent = Boolean(object.canPlanCompanyEvent);
    } else {
      message.canPlanCompanyEvent = false;
    }
    return message;
  },

  toJSON(message: GetUserInfoResponse_Permissions): unknown {
    const obj: any = {};
    message.canImpersonate !== undefined && (obj.canImpersonate = message.canImpersonate);
    message.canMassUpload !== undefined && (obj.canMassUpload = message.canMassUpload);
    message.canPlanCompanyEvent !== undefined &&
      (obj.canPlanCompanyEvent = message.canPlanCompanyEvent);
    return obj;
  },

  fromPartial(
    object: DeepPartial<GetUserInfoResponse_Permissions>,
  ): GetUserInfoResponse_Permissions {
    const message = { ...baseGetUserInfoResponse_Permissions } as GetUserInfoResponse_Permissions;
    if (object.canImpersonate !== undefined && object.canImpersonate !== null) {
      message.canImpersonate = object.canImpersonate;
    } else {
      message.canImpersonate = false;
    }
    if (object.canMassUpload !== undefined && object.canMassUpload !== null) {
      message.canMassUpload = object.canMassUpload;
    } else {
      message.canMassUpload = false;
    }
    if (object.canPlanCompanyEvent !== undefined && object.canPlanCompanyEvent !== null) {
      message.canPlanCompanyEvent = object.canPlanCompanyEvent;
    } else {
      message.canPlanCompanyEvent = false;
    }
    return message;
  },
};

const baseGetUserInfoResponse_Impersonator: object = {};

export const GetUserInfoResponse_Impersonator = {
  encode(
    message: GetUserInfoResponse_Impersonator,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.user !== undefined) {
      User.encode(message.user, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserInfoResponse_Impersonator {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetUserInfoResponse_Impersonator } as GetUserInfoResponse_Impersonator;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.user = User.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetUserInfoResponse_Impersonator {
    const message = { ...baseGetUserInfoResponse_Impersonator } as GetUserInfoResponse_Impersonator;
    if (object.user !== undefined && object.user !== null) {
      message.user = User.fromJSON(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },

  toJSON(message: GetUserInfoResponse_Impersonator): unknown {
    const obj: any = {};
    message.user !== undefined && (obj.user = message.user ? User.toJSON(message.user) : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<GetUserInfoResponse_Impersonator>,
  ): GetUserInfoResponse_Impersonator {
    const message = { ...baseGetUserInfoResponse_Impersonator } as GetUserInfoResponse_Impersonator;
    if (object.user !== undefined && object.user !== null) {
      message.user = User.fromPartial(object.user);
    } else {
      message.user = undefined;
    }
    return message;
  },
};

const baseGetMyImpersonateesRequest: object = {};

export const GetMyImpersonateesRequest = {
  encode(_: GetMyImpersonateesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyImpersonateesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetMyImpersonateesRequest } as GetMyImpersonateesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetMyImpersonateesRequest {
    const message = { ...baseGetMyImpersonateesRequest } as GetMyImpersonateesRequest;
    return message;
  },

  toJSON(_: GetMyImpersonateesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<GetMyImpersonateesRequest>): GetMyImpersonateesRequest {
    const message = { ...baseGetMyImpersonateesRequest } as GetMyImpersonateesRequest;
    return message;
  },
};

const baseGetMyImpersonateesResponse: object = { status: 0, message: '', isAllowedAll: false };

export const GetMyImpersonateesResponse = {
  encode(
    message: GetMyImpersonateesResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.isAllowedAll === true) {
      writer.uint32(24).bool(message.isAllowedAll);
    }
    for (const v of message.impersonatees) {
      EmployeeFound.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMyImpersonateesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetMyImpersonateesResponse } as GetMyImpersonateesResponse;
    message.impersonatees = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.isAllowedAll = reader.bool();
          break;
        case 4:
          message.impersonatees.push(EmployeeFound.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetMyImpersonateesResponse {
    const message = { ...baseGetMyImpersonateesResponse } as GetMyImpersonateesResponse;
    message.impersonatees = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.isAllowedAll !== undefined && object.isAllowedAll !== null) {
      message.isAllowedAll = Boolean(object.isAllowedAll);
    } else {
      message.isAllowedAll = false;
    }
    if (object.impersonatees !== undefined && object.impersonatees !== null) {
      for (const e of object.impersonatees) {
        message.impersonatees.push(EmployeeFound.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: GetMyImpersonateesResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.isAllowedAll !== undefined && (obj.isAllowedAll = message.isAllowedAll);
    if (message.impersonatees) {
      obj.impersonatees = message.impersonatees.map((e) =>
        e ? EmployeeFound.toJSON(e) : undefined,
      );
    } else {
      obj.impersonatees = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetMyImpersonateesResponse>): GetMyImpersonateesResponse {
    const message = { ...baseGetMyImpersonateesResponse } as GetMyImpersonateesResponse;
    message.impersonatees = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.isAllowedAll !== undefined && object.isAllowedAll !== null) {
      message.isAllowedAll = object.isAllowedAll;
    } else {
      message.isAllowedAll = false;
    }
    if (object.impersonatees !== undefined && object.impersonatees !== null) {
      for (const e of object.impersonatees) {
        message.impersonatees.push(EmployeeFound.fromPartial(e));
      }
    }
    return message;
  },
};

const baseGetAllOfficesRequest: object = {};

export const GetAllOfficesRequest = {
  encode(_: GetAllOfficesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllOfficesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetAllOfficesRequest } as GetAllOfficesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetAllOfficesRequest {
    const message = { ...baseGetAllOfficesRequest } as GetAllOfficesRequest;
    return message;
  },

  toJSON(_: GetAllOfficesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<GetAllOfficesRequest>): GetAllOfficesRequest {
    const message = { ...baseGetAllOfficesRequest } as GetAllOfficesRequest;
    return message;
  },
};

const baseGetAllOfficesResponse: object = { status: 0, message: '' };

export const GetAllOfficesResponse = {
  encode(message: GetAllOfficesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    Object.entries(message.offices).forEach(([key, value]) => {
      GetAllOfficesResponse_OfficesEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllOfficesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetAllOfficesResponse } as GetAllOfficesResponse;
    message.offices = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          const entry3 = GetAllOfficesResponse_OfficesEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.offices[entry3.key] = entry3.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllOfficesResponse {
    const message = { ...baseGetAllOfficesResponse } as GetAllOfficesResponse;
    message.offices = {};
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.offices !== undefined && object.offices !== null) {
      Object.entries(object.offices).forEach(([key, value]) => {
        message.offices[key] = Office.fromJSON(value);
      });
    }
    return message;
  },

  toJSON(message: GetAllOfficesResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    obj.offices = {};
    if (message.offices) {
      Object.entries(message.offices).forEach(([k, v]) => {
        obj.offices[k] = Office.toJSON(v);
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetAllOfficesResponse>): GetAllOfficesResponse {
    const message = { ...baseGetAllOfficesResponse } as GetAllOfficesResponse;
    message.offices = {};
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.offices !== undefined && object.offices !== null) {
      Object.entries(object.offices).forEach(([key, value]) => {
        if (value !== undefined) {
          message.offices[key] = Office.fromPartial(value);
        }
      });
    }
    return message;
  },
};

const baseGetAllOfficesResponse_OfficesEntry: object = { key: '0' };

export const GetAllOfficesResponse_OfficesEntry = {
  encode(
    message: GetAllOfficesResponse_OfficesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '0') {
      writer.uint32(8).int64(message.key);
    }
    if (message.value !== undefined) {
      Office.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllOfficesResponse_OfficesEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseGetAllOfficesResponse_OfficesEntry,
    } as GetAllOfficesResponse_OfficesEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = longToString(reader.int64() as Long);
          break;
        case 2:
          message.value = Office.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllOfficesResponse_OfficesEntry {
    const message = {
      ...baseGetAllOfficesResponse_OfficesEntry,
    } as GetAllOfficesResponse_OfficesEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = '0';
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = Office.fromJSON(object.value);
    } else {
      message.value = undefined;
    }
    return message;
  },

  toJSON(message: GetAllOfficesResponse_OfficesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? Office.toJSON(message.value) : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<GetAllOfficesResponse_OfficesEntry>,
  ): GetAllOfficesResponse_OfficesEntry {
    const message = {
      ...baseGetAllOfficesResponse_OfficesEntry,
    } as GetAllOfficesResponse_OfficesEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = '0';
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = Office.fromPartial(object.value);
    } else {
      message.value = undefined;
    }
    return message;
  },
};

const baseOfficeFloors: object = {};

export const OfficeFloors = {
  encode(message: OfficeFloors, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.floors) {
      Floor.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): OfficeFloors {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseOfficeFloors } as OfficeFloors;
    message.floors = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.floors.push(Floor.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): OfficeFloors {
    const message = { ...baseOfficeFloors } as OfficeFloors;
    message.floors = [];
    if (object.floors !== undefined && object.floors !== null) {
      for (const e of object.floors) {
        message.floors.push(Floor.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: OfficeFloors): unknown {
    const obj: any = {};
    if (message.floors) {
      obj.floors = message.floors.map((e) => (e ? Floor.toJSON(e) : undefined));
    } else {
      obj.floors = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<OfficeFloors>): OfficeFloors {
    const message = { ...baseOfficeFloors } as OfficeFloors;
    message.floors = [];
    if (object.floors !== undefined && object.floors !== null) {
      for (const e of object.floors) {
        message.floors.push(Floor.fromPartial(e));
      }
    }
    return message;
  },
};

const baseGetAllOfficeFloorsRequest: object = {};

export const GetAllOfficeFloorsRequest = {
  encode(_: GetAllOfficeFloorsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllOfficeFloorsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetAllOfficeFloorsRequest } as GetAllOfficeFloorsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetAllOfficeFloorsRequest {
    const message = { ...baseGetAllOfficeFloorsRequest } as GetAllOfficeFloorsRequest;
    return message;
  },

  toJSON(_: GetAllOfficeFloorsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<GetAllOfficeFloorsRequest>): GetAllOfficeFloorsRequest {
    const message = { ...baseGetAllOfficeFloorsRequest } as GetAllOfficeFloorsRequest;
    return message;
  },
};

const baseGetAllOfficeFloorsResponse: object = { status: 0, message: '' };

export const GetAllOfficeFloorsResponse = {
  encode(
    message: GetAllOfficeFloorsResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    Object.entries(message.officeFloors).forEach(([key, value]) => {
      GetAllOfficeFloorsResponse_OfficeFloorsEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllOfficeFloorsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetAllOfficeFloorsResponse } as GetAllOfficeFloorsResponse;
    message.officeFloors = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          const entry3 = GetAllOfficeFloorsResponse_OfficeFloorsEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry3.value !== undefined) {
            message.officeFloors[entry3.key] = entry3.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllOfficeFloorsResponse {
    const message = { ...baseGetAllOfficeFloorsResponse } as GetAllOfficeFloorsResponse;
    message.officeFloors = {};
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.officeFloors !== undefined && object.officeFloors !== null) {
      Object.entries(object.officeFloors).forEach(([key, value]) => {
        message.officeFloors[key] = OfficeFloors.fromJSON(value);
      });
    }
    return message;
  },

  toJSON(message: GetAllOfficeFloorsResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    obj.officeFloors = {};
    if (message.officeFloors) {
      Object.entries(message.officeFloors).forEach(([k, v]) => {
        obj.officeFloors[k] = OfficeFloors.toJSON(v);
      });
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetAllOfficeFloorsResponse>): GetAllOfficeFloorsResponse {
    const message = { ...baseGetAllOfficeFloorsResponse } as GetAllOfficeFloorsResponse;
    message.officeFloors = {};
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.officeFloors !== undefined && object.officeFloors !== null) {
      Object.entries(object.officeFloors).forEach(([key, value]) => {
        if (value !== undefined) {
          message.officeFloors[key] = OfficeFloors.fromPartial(value);
        }
      });
    }
    return message;
  },
};

const baseGetAllOfficeFloorsResponse_OfficeFloorsEntry: object = { key: '0' };

export const GetAllOfficeFloorsResponse_OfficeFloorsEntry = {
  encode(
    message: GetAllOfficeFloorsResponse_OfficeFloorsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '0') {
      writer.uint32(8).int64(message.key);
    }
    if (message.value !== undefined) {
      OfficeFloors.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAllOfficeFloorsResponse_OfficeFloorsEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseGetAllOfficeFloorsResponse_OfficeFloorsEntry,
    } as GetAllOfficeFloorsResponse_OfficeFloorsEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = longToString(reader.int64() as Long);
          break;
        case 2:
          message.value = OfficeFloors.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllOfficeFloorsResponse_OfficeFloorsEntry {
    const message = {
      ...baseGetAllOfficeFloorsResponse_OfficeFloorsEntry,
    } as GetAllOfficeFloorsResponse_OfficeFloorsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = '0';
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = OfficeFloors.fromJSON(object.value);
    } else {
      message.value = undefined;
    }
    return message;
  },

  toJSON(message: GetAllOfficeFloorsResponse_OfficeFloorsEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? OfficeFloors.toJSON(message.value) : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<GetAllOfficeFloorsResponse_OfficeFloorsEntry>,
  ): GetAllOfficeFloorsResponse_OfficeFloorsEntry {
    const message = {
      ...baseGetAllOfficeFloorsResponse_OfficeFloorsEntry,
    } as GetAllOfficeFloorsResponse_OfficeFloorsEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = '0';
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = OfficeFloors.fromPartial(object.value);
    } else {
      message.value = undefined;
    }
    return message;
  },
};

const baseGetOfficeBlockedDatesRequest: object = { officeId: '0' };

export const GetOfficeBlockedDatesRequest = {
  encode(
    message: GetOfficeBlockedDatesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.officeId !== '0') {
      writer.uint32(8).int64(message.officeId);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfficeBlockedDatesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetOfficeBlockedDatesRequest } as GetOfficeBlockedDatesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.officeId = longToString(reader.int64() as Long);
          break;
        case 2:
          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetOfficeBlockedDatesRequest {
    const message = { ...baseGetOfficeBlockedDatesRequest } as GetOfficeBlockedDatesRequest;
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = String(object.officeId);
    } else {
      message.officeId = '0';
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = fromJsonTimestamp(object.startDate);
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = fromJsonTimestamp(object.endDate);
    } else {
      message.endDate = undefined;
    }
    return message;
  },

  toJSON(message: GetOfficeBlockedDatesRequest): unknown {
    const obj: any = {};
    message.officeId !== undefined && (obj.officeId = message.officeId);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<GetOfficeBlockedDatesRequest>): GetOfficeBlockedDatesRequest {
    const message = { ...baseGetOfficeBlockedDatesRequest } as GetOfficeBlockedDatesRequest;
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = object.officeId;
    } else {
      message.officeId = '0';
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = undefined;
    }
    return message;
  },
};

const baseGetOfficeBlockedDatesResponse: object = { status: 0, message: '' };

export const GetOfficeBlockedDatesResponse = {
  encode(
    message: GetOfficeBlockedDatesResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.dates) {
      Timestamp.encode(toTimestamp(v!), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfficeBlockedDatesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetOfficeBlockedDatesResponse } as GetOfficeBlockedDatesResponse;
    message.dates = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.dates.push(fromTimestamp(Timestamp.decode(reader, reader.uint32())));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetOfficeBlockedDatesResponse {
    const message = { ...baseGetOfficeBlockedDatesResponse } as GetOfficeBlockedDatesResponse;
    message.dates = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.dates !== undefined && object.dates !== null) {
      for (const e of object.dates) {
        message.dates.push(fromJsonTimestamp(e));
      }
    }
    return message;
  },

  toJSON(message: GetOfficeBlockedDatesResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.dates) {
      obj.dates = message.dates.map((e) => e.toISOString());
    } else {
      obj.dates = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetOfficeBlockedDatesResponse>): GetOfficeBlockedDatesResponse {
    const message = { ...baseGetOfficeBlockedDatesResponse } as GetOfficeBlockedDatesResponse;
    message.dates = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.dates !== undefined && object.dates !== null) {
      for (const e of object.dates) {
        message.dates.push(e);
      }
    }
    return message;
  },
};

const baseBlockedDates: object = {};

export const BlockedDates = {
  encode(message: BlockedDates, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.dates) {
      Timestamp.encode(toTimestamp(v!), writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BlockedDates {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseBlockedDates } as BlockedDates;
    message.dates = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.dates.push(fromTimestamp(Timestamp.decode(reader, reader.uint32())));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BlockedDates {
    const message = { ...baseBlockedDates } as BlockedDates;
    message.dates = [];
    if (object.dates !== undefined && object.dates !== null) {
      for (const e of object.dates) {
        message.dates.push(fromJsonTimestamp(e));
      }
    }
    return message;
  },

  toJSON(message: BlockedDates): unknown {
    const obj: any = {};
    if (message.dates) {
      obj.dates = message.dates.map((e) => e.toISOString());
    } else {
      obj.dates = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<BlockedDates>): BlockedDates {
    const message = { ...baseBlockedDates } as BlockedDates;
    message.dates = [];
    if (object.dates !== undefined && object.dates !== null) {
      for (const e of object.dates) {
        message.dates.push(e);
      }
    }
    return message;
  },
};

const baseGetAllOfficesBlockedDatesRequest: object = {};

export const GetAllOfficesBlockedDatesRequest = {
  encode(
    message: GetAllOfficesBlockedDatesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllOfficesBlockedDatesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetAllOfficesBlockedDatesRequest } as GetAllOfficesBlockedDatesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 2:
          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllOfficesBlockedDatesRequest {
    const message = { ...baseGetAllOfficesBlockedDatesRequest } as GetAllOfficesBlockedDatesRequest;
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = fromJsonTimestamp(object.startDate);
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = fromJsonTimestamp(object.endDate);
    } else {
      message.endDate = undefined;
    }
    return message;
  },

  toJSON(message: GetAllOfficesBlockedDatesRequest): unknown {
    const obj: any = {};
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    return obj;
  },

  fromPartial(
    object: DeepPartial<GetAllOfficesBlockedDatesRequest>,
  ): GetAllOfficesBlockedDatesRequest {
    const message = { ...baseGetAllOfficesBlockedDatesRequest } as GetAllOfficesBlockedDatesRequest;
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = undefined;
    }
    return message;
  },
};

const baseGetAllOfficesBlockedDatesResponse: object = { status: 0, message: '' };

export const GetAllOfficesBlockedDatesResponse = {
  encode(
    message: GetAllOfficesBlockedDatesResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    Object.entries(message.blockedDates).forEach(([key, value]) => {
      GetAllOfficesBlockedDatesResponse_BlockedDatesEntry.encode(
        { key: key as any, value },
        writer.uint32(26).fork(),
      ).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllOfficesBlockedDatesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseGetAllOfficesBlockedDatesResponse,
    } as GetAllOfficesBlockedDatesResponse;
    message.blockedDates = {};
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          const entry3 = GetAllOfficesBlockedDatesResponse_BlockedDatesEntry.decode(
            reader,
            reader.uint32(),
          );
          if (entry3.value !== undefined) {
            message.blockedDates[entry3.key] = entry3.value;
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllOfficesBlockedDatesResponse {
    const message = {
      ...baseGetAllOfficesBlockedDatesResponse,
    } as GetAllOfficesBlockedDatesResponse;
    message.blockedDates = {};
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.blockedDates !== undefined && object.blockedDates !== null) {
      Object.entries(object.blockedDates).forEach(([key, value]) => {
        message.blockedDates[key] = BlockedDates.fromJSON(value);
      });
    }
    return message;
  },

  toJSON(message: GetAllOfficesBlockedDatesResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    obj.blockedDates = {};
    if (message.blockedDates) {
      Object.entries(message.blockedDates).forEach(([k, v]) => {
        obj.blockedDates[k] = BlockedDates.toJSON(v);
      });
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<GetAllOfficesBlockedDatesResponse>,
  ): GetAllOfficesBlockedDatesResponse {
    const message = {
      ...baseGetAllOfficesBlockedDatesResponse,
    } as GetAllOfficesBlockedDatesResponse;
    message.blockedDates = {};
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.blockedDates !== undefined && object.blockedDates !== null) {
      Object.entries(object.blockedDates).forEach(([key, value]) => {
        if (value !== undefined) {
          message.blockedDates[key] = BlockedDates.fromPartial(value);
        }
      });
    }
    return message;
  },
};

const baseGetAllOfficesBlockedDatesResponse_BlockedDatesEntry: object = { key: '0' };

export const GetAllOfficesBlockedDatesResponse_BlockedDatesEntry = {
  encode(
    message: GetAllOfficesBlockedDatesResponse_BlockedDatesEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== '0') {
      writer.uint32(8).int64(message.key);
    }
    if (message.value !== undefined) {
      BlockedDates.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(
    input: _m0.Reader | Uint8Array,
    length?: number,
  ): GetAllOfficesBlockedDatesResponse_BlockedDatesEntry {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseGetAllOfficesBlockedDatesResponse_BlockedDatesEntry,
    } as GetAllOfficesBlockedDatesResponse_BlockedDatesEntry;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.key = longToString(reader.int64() as Long);
          break;
        case 2:
          message.value = BlockedDates.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllOfficesBlockedDatesResponse_BlockedDatesEntry {
    const message = {
      ...baseGetAllOfficesBlockedDatesResponse_BlockedDatesEntry,
    } as GetAllOfficesBlockedDatesResponse_BlockedDatesEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = String(object.key);
    } else {
      message.key = '0';
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = BlockedDates.fromJSON(object.value);
    } else {
      message.value = undefined;
    }
    return message;
  },

  toJSON(message: GetAllOfficesBlockedDatesResponse_BlockedDatesEntry): unknown {
    const obj: any = {};
    message.key !== undefined && (obj.key = message.key);
    message.value !== undefined &&
      (obj.value = message.value ? BlockedDates.toJSON(message.value) : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<GetAllOfficesBlockedDatesResponse_BlockedDatesEntry>,
  ): GetAllOfficesBlockedDatesResponse_BlockedDatesEntry {
    const message = {
      ...baseGetAllOfficesBlockedDatesResponse_BlockedDatesEntry,
    } as GetAllOfficesBlockedDatesResponse_BlockedDatesEntry;
    if (object.key !== undefined && object.key !== null) {
      message.key = object.key;
    } else {
      message.key = '0';
    }
    if (object.value !== undefined && object.value !== null) {
      message.value = BlockedDates.fromPartial(object.value);
    } else {
      message.value = undefined;
    }
    return message;
  },
};

const baseGetFullyBookedDatesRequest: object = { officeId: '0' };

export const GetFullyBookedDatesRequest = {
  encode(
    message: GetFullyBookedDatesRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.officeId !== '0') {
      writer.uint32(8).int64(message.officeId);
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(18).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFullyBookedDatesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetFullyBookedDatesRequest } as GetFullyBookedDatesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.officeId = longToString(reader.int64() as Long);
          break;
        case 2:
          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 3:
          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetFullyBookedDatesRequest {
    const message = { ...baseGetFullyBookedDatesRequest } as GetFullyBookedDatesRequest;
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = String(object.officeId);
    } else {
      message.officeId = '0';
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = fromJsonTimestamp(object.startDate);
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = fromJsonTimestamp(object.endDate);
    } else {
      message.endDate = undefined;
    }
    return message;
  },

  toJSON(message: GetFullyBookedDatesRequest): unknown {
    const obj: any = {};
    message.officeId !== undefined && (obj.officeId = message.officeId);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<GetFullyBookedDatesRequest>): GetFullyBookedDatesRequest {
    const message = { ...baseGetFullyBookedDatesRequest } as GetFullyBookedDatesRequest;
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = object.officeId;
    } else {
      message.officeId = '0';
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = undefined;
    }
    return message;
  },
};

const baseGetFullyBookedDatesResponse: object = { status: 0, message: '' };

export const GetFullyBookedDatesResponse = {
  encode(
    message: GetFullyBookedDatesResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.fullyBookedDates) {
      Timestamp.encode(toTimestamp(v!), writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetFullyBookedDatesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetFullyBookedDatesResponse } as GetFullyBookedDatesResponse;
    message.fullyBookedDates = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.fullyBookedDates.push(fromTimestamp(Timestamp.decode(reader, reader.uint32())));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetFullyBookedDatesResponse {
    const message = { ...baseGetFullyBookedDatesResponse } as GetFullyBookedDatesResponse;
    message.fullyBookedDates = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.fullyBookedDates !== undefined && object.fullyBookedDates !== null) {
      for (const e of object.fullyBookedDates) {
        message.fullyBookedDates.push(fromJsonTimestamp(e));
      }
    }
    return message;
  },

  toJSON(message: GetFullyBookedDatesResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.fullyBookedDates) {
      obj.fullyBookedDates = message.fullyBookedDates.map((e) => e.toISOString());
    } else {
      obj.fullyBookedDates = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetFullyBookedDatesResponse>): GetFullyBookedDatesResponse {
    const message = { ...baseGetFullyBookedDatesResponse } as GetFullyBookedDatesResponse;
    message.fullyBookedDates = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.fullyBookedDates !== undefined && object.fullyBookedDates !== null) {
      for (const e of object.fullyBookedDates) {
        message.fullyBookedDates.push(e);
      }
    }
    return message;
  },
};

const baseGetOfficeUsagesRequest: object = { officeId: '0' };

export const GetOfficeUsagesRequest = {
  encode(message: GetOfficeUsagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.officeId !== '0') {
      writer.uint32(8).int64(message.officeId);
    }
    for (const v of message.dates) {
      Timestamp.encode(toTimestamp(v!), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfficeUsagesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetOfficeUsagesRequest } as GetOfficeUsagesRequest;
    message.dates = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.officeId = longToString(reader.int64() as Long);
          break;
        case 2:
          message.dates.push(fromTimestamp(Timestamp.decode(reader, reader.uint32())));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetOfficeUsagesRequest {
    const message = { ...baseGetOfficeUsagesRequest } as GetOfficeUsagesRequest;
    message.dates = [];
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = String(object.officeId);
    } else {
      message.officeId = '0';
    }
    if (object.dates !== undefined && object.dates !== null) {
      for (const e of object.dates) {
        message.dates.push(fromJsonTimestamp(e));
      }
    }
    return message;
  },

  toJSON(message: GetOfficeUsagesRequest): unknown {
    const obj: any = {};
    message.officeId !== undefined && (obj.officeId = message.officeId);
    if (message.dates) {
      obj.dates = message.dates.map((e) => e.toISOString());
    } else {
      obj.dates = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetOfficeUsagesRequest>): GetOfficeUsagesRequest {
    const message = { ...baseGetOfficeUsagesRequest } as GetOfficeUsagesRequest;
    message.dates = [];
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = object.officeId;
    } else {
      message.officeId = '0';
    }
    if (object.dates !== undefined && object.dates !== null) {
      for (const e of object.dates) {
        message.dates.push(e);
      }
    }
    return message;
  },
};

const baseGetOfficeUsagesResponse: object = { status: 0, message: '' };

export const GetOfficeUsagesResponse = {
  encode(message: GetOfficeUsagesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.officeUsages) {
      OfficeUsage.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetOfficeUsagesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetOfficeUsagesResponse } as GetOfficeUsagesResponse;
    message.officeUsages = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.officeUsages.push(OfficeUsage.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetOfficeUsagesResponse {
    const message = { ...baseGetOfficeUsagesResponse } as GetOfficeUsagesResponse;
    message.officeUsages = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.officeUsages !== undefined && object.officeUsages !== null) {
      for (const e of object.officeUsages) {
        message.officeUsages.push(OfficeUsage.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: GetOfficeUsagesResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.officeUsages) {
      obj.officeUsages = message.officeUsages.map((e) => (e ? OfficeUsage.toJSON(e) : undefined));
    } else {
      obj.officeUsages = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetOfficeUsagesResponse>): GetOfficeUsagesResponse {
    const message = { ...baseGetOfficeUsagesResponse } as GetOfficeUsagesResponse;
    message.officeUsages = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.officeUsages !== undefined && object.officeUsages !== null) {
      for (const e of object.officeUsages) {
        message.officeUsages.push(OfficeUsage.fromPartial(e));
      }
    }
    return message;
  },
};

const baseGetEmployeeVisitsRequest: object = {};

export const GetEmployeeVisitsRequest = {
  encode(message: GetEmployeeVisitsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEmployeeVisitsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetEmployeeVisitsRequest } as GetEmployeeVisitsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 2:
          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetEmployeeVisitsRequest {
    const message = { ...baseGetEmployeeVisitsRequest } as GetEmployeeVisitsRequest;
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = fromJsonTimestamp(object.startDate);
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = fromJsonTimestamp(object.endDate);
    } else {
      message.endDate = undefined;
    }
    return message;
  },

  toJSON(message: GetEmployeeVisitsRequest): unknown {
    const obj: any = {};
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<GetEmployeeVisitsRequest>): GetEmployeeVisitsRequest {
    const message = { ...baseGetEmployeeVisitsRequest } as GetEmployeeVisitsRequest;
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = undefined;
    }
    return message;
  },
};

const baseGetEmployeeVisitsResponse: object = { status: 0, message: '' };

export const GetEmployeeVisitsResponse = {
  encode(message: GetEmployeeVisitsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.visits) {
      OfficeVisit.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetEmployeeVisitsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetEmployeeVisitsResponse } as GetEmployeeVisitsResponse;
    message.visits = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.visits.push(OfficeVisit.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetEmployeeVisitsResponse {
    const message = { ...baseGetEmployeeVisitsResponse } as GetEmployeeVisitsResponse;
    message.visits = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.visits !== undefined && object.visits !== null) {
      for (const e of object.visits) {
        message.visits.push(OfficeVisit.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: GetEmployeeVisitsResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.visits) {
      obj.visits = message.visits.map((e) => (e ? OfficeVisit.toJSON(e) : undefined));
    } else {
      obj.visits = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetEmployeeVisitsResponse>): GetEmployeeVisitsResponse {
    const message = { ...baseGetEmployeeVisitsResponse } as GetEmployeeVisitsResponse;
    message.visits = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.visits !== undefined && object.visits !== null) {
      for (const e of object.visits) {
        message.visits.push(OfficeVisit.fromPartial(e));
      }
    }
    return message;
  },
};

const baseBookOfficeVisitRequest: object = {};

export const BookOfficeVisitRequest = {
  encode(message: BookOfficeVisitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.visit !== undefined) {
      BookingOfficeVisit.encode(message.visit, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookOfficeVisitRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseBookOfficeVisitRequest } as BookOfficeVisitRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.visit = BookingOfficeVisit.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BookOfficeVisitRequest {
    const message = { ...baseBookOfficeVisitRequest } as BookOfficeVisitRequest;
    if (object.visit !== undefined && object.visit !== null) {
      message.visit = BookingOfficeVisit.fromJSON(object.visit);
    } else {
      message.visit = undefined;
    }
    return message;
  },

  toJSON(message: BookOfficeVisitRequest): unknown {
    const obj: any = {};
    message.visit !== undefined &&
      (obj.visit = message.visit ? BookingOfficeVisit.toJSON(message.visit) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<BookOfficeVisitRequest>): BookOfficeVisitRequest {
    const message = { ...baseBookOfficeVisitRequest } as BookOfficeVisitRequest;
    if (object.visit !== undefined && object.visit !== null) {
      message.visit = BookingOfficeVisit.fromPartial(object.visit);
    } else {
      message.visit = undefined;
    }
    return message;
  },
};

const baseBookOfficeVisitResponse: object = { status: 0, message: '' };

export const BookOfficeVisitResponse = {
  encode(message: BookOfficeVisitResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.bookedVisits) {
      OfficeVisit.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.rejectedBookings) {
      RejectedBooking.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BookOfficeVisitResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseBookOfficeVisitResponse } as BookOfficeVisitResponse;
    message.bookedVisits = [];
    message.rejectedBookings = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.bookedVisits.push(OfficeVisit.decode(reader, reader.uint32()));
          break;
        case 4:
          message.rejectedBookings.push(RejectedBooking.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): BookOfficeVisitResponse {
    const message = { ...baseBookOfficeVisitResponse } as BookOfficeVisitResponse;
    message.bookedVisits = [];
    message.rejectedBookings = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.bookedVisits !== undefined && object.bookedVisits !== null) {
      for (const e of object.bookedVisits) {
        message.bookedVisits.push(OfficeVisit.fromJSON(e));
      }
    }
    if (object.rejectedBookings !== undefined && object.rejectedBookings !== null) {
      for (const e of object.rejectedBookings) {
        message.rejectedBookings.push(RejectedBooking.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: BookOfficeVisitResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.bookedVisits) {
      obj.bookedVisits = message.bookedVisits.map((e) => (e ? OfficeVisit.toJSON(e) : undefined));
    } else {
      obj.bookedVisits = [];
    }
    if (message.rejectedBookings) {
      obj.rejectedBookings = message.rejectedBookings.map((e) =>
        e ? RejectedBooking.toJSON(e) : undefined,
      );
    } else {
      obj.rejectedBookings = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<BookOfficeVisitResponse>): BookOfficeVisitResponse {
    const message = { ...baseBookOfficeVisitResponse } as BookOfficeVisitResponse;
    message.bookedVisits = [];
    message.rejectedBookings = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.bookedVisits !== undefined && object.bookedVisits !== null) {
      for (const e of object.bookedVisits) {
        message.bookedVisits.push(OfficeVisit.fromPartial(e));
      }
    }
    if (object.rejectedBookings !== undefined && object.rejectedBookings !== null) {
      for (const e of object.rejectedBookings) {
        message.rejectedBookings.push(RejectedBooking.fromPartial(e));
      }
    }
    return message;
  },
};

const baseCancelOfficeVisitRequest: object = { visitIds: '0' };

export const CancelOfficeVisitRequest = {
  encode(message: CancelOfficeVisitRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.visitIds) {
      writer.int64(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelOfficeVisitRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCancelOfficeVisitRequest } as CancelOfficeVisitRequest;
    message.visitIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.visitIds.push(longToString(reader.int64() as Long));
            }
          } else {
            message.visitIds.push(longToString(reader.int64() as Long));
          }
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CancelOfficeVisitRequest {
    const message = { ...baseCancelOfficeVisitRequest } as CancelOfficeVisitRequest;
    message.visitIds = [];
    if (object.visitIds !== undefined && object.visitIds !== null) {
      for (const e of object.visitIds) {
        message.visitIds.push(String(e));
      }
    }
    return message;
  },

  toJSON(message: CancelOfficeVisitRequest): unknown {
    const obj: any = {};
    if (message.visitIds) {
      obj.visitIds = message.visitIds.map((e) => e);
    } else {
      obj.visitIds = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<CancelOfficeVisitRequest>): CancelOfficeVisitRequest {
    const message = { ...baseCancelOfficeVisitRequest } as CancelOfficeVisitRequest;
    message.visitIds = [];
    if (object.visitIds !== undefined && object.visitIds !== null) {
      for (const e of object.visitIds) {
        message.visitIds.push(e);
      }
    }
    return message;
  },
};

const baseCancelOfficeVisitResponse: object = { status: 0, message: '' };

export const CancelOfficeVisitResponse = {
  encode(message: CancelOfficeVisitResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.canceledVisits) {
      OfficeVisit.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelOfficeVisitResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCancelOfficeVisitResponse } as CancelOfficeVisitResponse;
    message.canceledVisits = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.canceledVisits.push(OfficeVisit.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CancelOfficeVisitResponse {
    const message = { ...baseCancelOfficeVisitResponse } as CancelOfficeVisitResponse;
    message.canceledVisits = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.canceledVisits !== undefined && object.canceledVisits !== null) {
      for (const e of object.canceledVisits) {
        message.canceledVisits.push(OfficeVisit.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: CancelOfficeVisitResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.canceledVisits) {
      obj.canceledVisits = message.canceledVisits.map((e) =>
        e ? OfficeVisit.toJSON(e) : undefined,
      );
    } else {
      obj.canceledVisits = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<CancelOfficeVisitResponse>): CancelOfficeVisitResponse {
    const message = { ...baseCancelOfficeVisitResponse } as CancelOfficeVisitResponse;
    message.canceledVisits = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.canceledVisits !== undefined && object.canceledVisits !== null) {
      for (const e of object.canceledVisits) {
        message.canceledVisits.push(OfficeVisit.fromPartial(e));
      }
    }
    return message;
  },
};

const baseGetHATTStatusRequest: object = {};

export const GetHATTStatusRequest = {
  encode(_: GetHATTStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetHATTStatusRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetHATTStatusRequest } as GetHATTStatusRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetHATTStatusRequest {
    const message = { ...baseGetHATTStatusRequest } as GetHATTStatusRequest;
    return message;
  },

  toJSON(_: GetHATTStatusRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<GetHATTStatusRequest>): GetHATTStatusRequest {
    const message = { ...baseGetHATTStatusRequest } as GetHATTStatusRequest;
    return message;
  },
};

const baseGetHATTStatusResponse: object = { status: 0, message: '', completed: false };

export const GetHATTStatusResponse = {
  encode(message: GetHATTStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.completed === true) {
      writer.uint32(24).bool(message.completed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetHATTStatusResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetHATTStatusResponse } as GetHATTStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.completed = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetHATTStatusResponse {
    const message = { ...baseGetHATTStatusResponse } as GetHATTStatusResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.completed !== undefined && object.completed !== null) {
      message.completed = Boolean(object.completed);
    } else {
      message.completed = false;
    }
    return message;
  },

  toJSON(message: GetHATTStatusResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.completed !== undefined && (obj.completed = message.completed);
    return obj;
  },

  fromPartial(object: DeepPartial<GetHATTStatusResponse>): GetHATTStatusResponse {
    const message = { ...baseGetHATTStatusResponse } as GetHATTStatusResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.completed !== undefined && object.completed !== null) {
      message.completed = object.completed;
    } else {
      message.completed = false;
    }
    return message;
  },
};

const baseGetVaccinationStatusRequest: object = {};

export const GetVaccinationStatusRequest = {
  encode(_: GetVaccinationStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVaccinationStatusRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetVaccinationStatusRequest } as GetVaccinationStatusRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetVaccinationStatusRequest {
    const message = { ...baseGetVaccinationStatusRequest } as GetVaccinationStatusRequest;
    return message;
  },

  toJSON(_: GetVaccinationStatusRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<GetVaccinationStatusRequest>): GetVaccinationStatusRequest {
    const message = { ...baseGetVaccinationStatusRequest } as GetVaccinationStatusRequest;
    return message;
  },
};

const baseGetVaccinationStatusResponse: object = { status: 0, message: '', approved: false };

export const GetVaccinationStatusResponse = {
  encode(
    message: GetVaccinationStatusResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.approved === true) {
      writer.uint32(24).bool(message.approved);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetVaccinationStatusResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetVaccinationStatusResponse } as GetVaccinationStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.approved = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetVaccinationStatusResponse {
    const message = { ...baseGetVaccinationStatusResponse } as GetVaccinationStatusResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.approved !== undefined && object.approved !== null) {
      message.approved = Boolean(object.approved);
    } else {
      message.approved = false;
    }
    return message;
  },

  toJSON(message: GetVaccinationStatusResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.approved !== undefined && (obj.approved = message.approved);
    return obj;
  },

  fromPartial(object: DeepPartial<GetVaccinationStatusResponse>): GetVaccinationStatusResponse {
    const message = { ...baseGetVaccinationStatusResponse } as GetVaccinationStatusResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.approved !== undefined && object.approved !== null) {
      message.approved = object.approved;
    } else {
      message.approved = false;
    }
    return message;
  },
};

const baseGetTrainingStatusRequest: object = {};

export const GetTrainingStatusRequest = {
  encode(_: GetTrainingStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTrainingStatusRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetTrainingStatusRequest } as GetTrainingStatusRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetTrainingStatusRequest {
    const message = { ...baseGetTrainingStatusRequest } as GetTrainingStatusRequest;
    return message;
  },

  toJSON(_: GetTrainingStatusRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<GetTrainingStatusRequest>): GetTrainingStatusRequest {
    const message = { ...baseGetTrainingStatusRequest } as GetTrainingStatusRequest;
    return message;
  },
};

const baseGetTrainingStatusResponse: object = { status: 0, message: '', completed: false };

export const GetTrainingStatusResponse = {
  encode(message: GetTrainingStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.completed === true) {
      writer.uint32(24).bool(message.completed);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTrainingStatusResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetTrainingStatusResponse } as GetTrainingStatusResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.completed = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetTrainingStatusResponse {
    const message = { ...baseGetTrainingStatusResponse } as GetTrainingStatusResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.completed !== undefined && object.completed !== null) {
      message.completed = Boolean(object.completed);
    } else {
      message.completed = false;
    }
    return message;
  },

  toJSON(message: GetTrainingStatusResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.completed !== undefined && (obj.completed = message.completed);
    return obj;
  },

  fromPartial(object: DeepPartial<GetTrainingStatusResponse>): GetTrainingStatusResponse {
    const message = { ...baseGetTrainingStatusResponse } as GetTrainingStatusResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.completed !== undefined && object.completed !== null) {
      message.completed = object.completed;
    } else {
      message.completed = false;
    }
    return message;
  },
};

// IS EXEMPT STARTS HERE
const baseIsBadgeExemptRequest: object = {userId: '0'};

export const IsBadgeExemptRequest = {
  encode(message: IsBadgeExemptRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userId !== '0') {
      writer.uint32(8).int64(message.userId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IsBadgeExemptRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseIsBadgeExemptRequest } as IsBadgeExemptRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.userId = longToString(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): IsBadgeExemptRequest {
    const message = { ...baseIsBadgeExemptRequest } as IsBadgeExemptRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = String(object.userId);
    } else {
      message.userId = '0';
    }
    return message;
  },

  toJSON(message: IsBadgeExemptRequest): unknown {
    const obj: any = {};
    message.userId !== undefined && (obj.userId = message.userId);
    return obj;
  },

  fromPartial(object: DeepPartial<IsBadgeExemptRequest>): IsBadgeExemptRequest {
    const message = { ...baseIsBadgeExemptRequest } as IsBadgeExemptRequest;
    if (object.userId !== undefined && object.userId !== null) {
      message.userId = object.userId;
    } else {
      message.userId = '0';
    }
    return message;
  },
};

const baseIsBadgeExemptResponse: object = { status: 0, message: '', isExempt: false };

export const IsBadgeExemptResponse = {
  encode(message: IsBadgeExemptResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.isExempt === true) {
      writer.uint32(24).bool(message.isExempt);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): IsBadgeExemptResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseIsBadgeExemptResponse } as IsBadgeExemptResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.isExempt = reader.bool();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): IsBadgeExemptResponse {
    const message = { ...baseIsBadgeExemptResponse } as IsBadgeExemptResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.isExempt !== undefined && object.isExempt !== null) {
      message.isExempt = Boolean(object.isExempt);
    } else {
      message.isExempt = false;
    }
    return message;
  },

  toJSON(message: IsBadgeExemptResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.isExempt !== undefined && (obj.isExempt = message.isExempt);
    return obj;
  },

  fromPartial(object: DeepPartial<IsBadgeExemptResponse>): IsBadgeExemptResponse {
    const message = { ...baseIsBadgeExemptResponse } as IsBadgeExemptResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.isExempt !== undefined && object.isExempt !== null) {
      message.isExempt = object.isExempt;
    } else {
      message.isExempt = false;
    }
    return message;
  },
};


// IS EXEMPT ENDS HERE

const baseGetSystemHealthRequest: object = {};

export const GetSystemHealthRequest = {
  encode(_: GetSystemHealthRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSystemHealthRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSystemHealthRequest } as GetSystemHealthRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetSystemHealthRequest {
    const message = { ...baseGetSystemHealthRequest } as GetSystemHealthRequest;
    return message;
  },

  toJSON(_: GetSystemHealthRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<GetSystemHealthRequest>): GetSystemHealthRequest {
    const message = { ...baseGetSystemHealthRequest } as GetSystemHealthRequest;
    return message;
  },
};

const baseGetSystemHealthResponse: object = { status: 0, message: '' };

export const GetSystemHealthResponse = {
  encode(message: GetSystemHealthResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.serviceStatuses) {
      ServiceStatus.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSystemHealthResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetSystemHealthResponse } as GetSystemHealthResponse;
    message.serviceStatuses = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.serviceStatuses.push(ServiceStatus.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetSystemHealthResponse {
    const message = { ...baseGetSystemHealthResponse } as GetSystemHealthResponse;
    message.serviceStatuses = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.serviceStatuses !== undefined && object.serviceStatuses !== null) {
      for (const e of object.serviceStatuses) {
        message.serviceStatuses.push(ServiceStatus.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: GetSystemHealthResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.serviceStatuses) {
      obj.serviceStatuses = message.serviceStatuses.map((e) =>
        e ? ServiceStatus.toJSON(e) : undefined,
      );
    } else {
      obj.serviceStatuses = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetSystemHealthResponse>): GetSystemHealthResponse {
    const message = { ...baseGetSystemHealthResponse } as GetSystemHealthResponse;
    message.serviceStatuses = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.serviceStatuses !== undefined && object.serviceStatuses !== null) {
      for (const e of object.serviceStatuses) {
        message.serviceStatuses.push(ServiceStatus.fromPartial(e));
      }
    }
    return message;
  },
};

const baseGetUserInboxMessagesRequest: object = {};

export const GetUserInboxMessagesRequest = {
  encode(_: GetUserInboxMessagesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserInboxMessagesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetUserInboxMessagesRequest } as GetUserInboxMessagesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetUserInboxMessagesRequest {
    const message = { ...baseGetUserInboxMessagesRequest } as GetUserInboxMessagesRequest;
    return message;
  },

  toJSON(_: GetUserInboxMessagesRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<GetUserInboxMessagesRequest>): GetUserInboxMessagesRequest {
    const message = { ...baseGetUserInboxMessagesRequest } as GetUserInboxMessagesRequest;
    return message;
  },
};

const baseGetUserInboxMessagesResponse: object = { status: 0, message: '' };

export const GetUserInboxMessagesResponse = {
  encode(
    message: GetUserInboxMessagesResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.inboxMessages) {
      UserInboxMessage.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserInboxMessagesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetUserInboxMessagesResponse } as GetUserInboxMessagesResponse;
    message.inboxMessages = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.inboxMessages.push(UserInboxMessage.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetUserInboxMessagesResponse {
    const message = { ...baseGetUserInboxMessagesResponse } as GetUserInboxMessagesResponse;
    message.inboxMessages = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.inboxMessages !== undefined && object.inboxMessages !== null) {
      for (const e of object.inboxMessages) {
        message.inboxMessages.push(UserInboxMessage.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: GetUserInboxMessagesResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.inboxMessages) {
      obj.inboxMessages = message.inboxMessages.map((e) =>
        e ? UserInboxMessage.toJSON(e) : undefined,
      );
    } else {
      obj.inboxMessages = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetUserInboxMessagesResponse>): GetUserInboxMessagesResponse {
    const message = { ...baseGetUserInboxMessagesResponse } as GetUserInboxMessagesResponse;
    message.inboxMessages = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.inboxMessages !== undefined && object.inboxMessages !== null) {
      for (const e of object.inboxMessages) {
        message.inboxMessages.push(UserInboxMessage.fromPartial(e));
      }
    }
    return message;
  },
};

const baseAcknowledgeUserInboxMessageRequest: object = { inboxMessageId: '0' };

export const AcknowledgeUserInboxMessageRequest = {
  encode(
    message: AcknowledgeUserInboxMessageRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.inboxMessageId !== '0') {
      writer.uint32(8).int64(message.inboxMessageId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcknowledgeUserInboxMessageRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseAcknowledgeUserInboxMessageRequest,
    } as AcknowledgeUserInboxMessageRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.inboxMessageId = longToString(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AcknowledgeUserInboxMessageRequest {
    const message = {
      ...baseAcknowledgeUserInboxMessageRequest,
    } as AcknowledgeUserInboxMessageRequest;
    if (object.inboxMessageId !== undefined && object.inboxMessageId !== null) {
      message.inboxMessageId = String(object.inboxMessageId);
    } else {
      message.inboxMessageId = '0';
    }
    return message;
  },

  toJSON(message: AcknowledgeUserInboxMessageRequest): unknown {
    const obj: any = {};
    message.inboxMessageId !== undefined && (obj.inboxMessageId = message.inboxMessageId);
    return obj;
  },

  fromPartial(
    object: DeepPartial<AcknowledgeUserInboxMessageRequest>,
  ): AcknowledgeUserInboxMessageRequest {
    const message = {
      ...baseAcknowledgeUserInboxMessageRequest,
    } as AcknowledgeUserInboxMessageRequest;
    if (object.inboxMessageId !== undefined && object.inboxMessageId !== null) {
      message.inboxMessageId = object.inboxMessageId;
    } else {
      message.inboxMessageId = '0';
    }
    return message;
  },
};

const baseAcknowledgeUserInboxMessageResponse: object = { status: 0, message: '' };

export const AcknowledgeUserInboxMessageResponse = {
  encode(
    message: AcknowledgeUserInboxMessageResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcknowledgeUserInboxMessageResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseAcknowledgeUserInboxMessageResponse,
    } as AcknowledgeUserInboxMessageResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AcknowledgeUserInboxMessageResponse {
    const message = {
      ...baseAcknowledgeUserInboxMessageResponse,
    } as AcknowledgeUserInboxMessageResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    return message;
  },

  toJSON(message: AcknowledgeUserInboxMessageResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  fromPartial(
    object: DeepPartial<AcknowledgeUserInboxMessageResponse>,
  ): AcknowledgeUserInboxMessageResponse {
    const message = {
      ...baseAcknowledgeUserInboxMessageResponse,
    } as AcknowledgeUserInboxMessageResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    return message;
  },
};

const baseUploadOfficeVisitsInBulkRequest: object = { filename: '' };

export const UploadOfficeVisitsInBulkRequest = {
  encode(
    message: UploadOfficeVisitsInBulkRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.filename !== '') {
      writer.uint32(10).string(message.filename);
    }
    if (message.file.length !== 0) {
      writer.uint32(18).bytes(message.file);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadOfficeVisitsInBulkRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUploadOfficeVisitsInBulkRequest } as UploadOfficeVisitsInBulkRequest;
    message.file = new Uint8Array();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.filename = reader.string();
          break;
        case 2:
          message.file = reader.bytes();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UploadOfficeVisitsInBulkRequest {
    const message = { ...baseUploadOfficeVisitsInBulkRequest } as UploadOfficeVisitsInBulkRequest;
    message.file = new Uint8Array();
    if (object.filename !== undefined && object.filename !== null) {
      message.filename = String(object.filename);
    } else {
      message.filename = '';
    }
    if (object.file !== undefined && object.file !== null) {
      message.file = bytesFromBase64(object.file);
    }
    return message;
  },

  toJSON(message: UploadOfficeVisitsInBulkRequest): unknown {
    const obj: any = {};
    message.filename !== undefined && (obj.filename = message.filename);
    message.file !== undefined &&
      (obj.file = base64FromBytes(message.file !== undefined ? message.file : new Uint8Array()));
    return obj;
  },

  fromPartial(
    object: DeepPartial<UploadOfficeVisitsInBulkRequest>,
  ): UploadOfficeVisitsInBulkRequest {
    const message = { ...baseUploadOfficeVisitsInBulkRequest } as UploadOfficeVisitsInBulkRequest;
    if (object.filename !== undefined && object.filename !== null) {
      message.filename = object.filename;
    } else {
      message.filename = '';
    }
    if (object.file !== undefined && object.file !== null) {
      message.file = object.file;
    } else {
      message.file = new Uint8Array();
    }
    return message;
  },
};

const baseUploadOfficeVisitsInBulkResponse: object = {
  status: 0,
  message: '',
  massUploadId: '0',
  result: 0,
};

export const UploadOfficeVisitsInBulkResponse = {
  encode(
    message: UploadOfficeVisitsInBulkResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.massUploadId !== '0') {
      writer.uint32(24).int64(message.massUploadId);
    }
    if (message.result !== 0) {
      writer.uint32(32).int32(message.result);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UploadOfficeVisitsInBulkResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseUploadOfficeVisitsInBulkResponse } as UploadOfficeVisitsInBulkResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.massUploadId = longToString(reader.int64() as Long);
          break;
        case 4:
          message.result = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): UploadOfficeVisitsInBulkResponse {
    const message = { ...baseUploadOfficeVisitsInBulkResponse } as UploadOfficeVisitsInBulkResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.massUploadId !== undefined && object.massUploadId !== null) {
      message.massUploadId = String(object.massUploadId);
    } else {
      message.massUploadId = '0';
    }
    if (object.result !== undefined && object.result !== null) {
      message.result = massUploadReportStatusFromJSON(object.result);
    } else {
      message.result = 0;
    }
    return message;
  },

  toJSON(message: UploadOfficeVisitsInBulkResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.massUploadId !== undefined && (obj.massUploadId = message.massUploadId);
    message.result !== undefined && (obj.result = massUploadReportStatusToJSON(message.result));
    return obj;
  },

  fromPartial(
    object: DeepPartial<UploadOfficeVisitsInBulkResponse>,
  ): UploadOfficeVisitsInBulkResponse {
    const message = { ...baseUploadOfficeVisitsInBulkResponse } as UploadOfficeVisitsInBulkResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.massUploadId !== undefined && object.massUploadId !== null) {
      message.massUploadId = object.massUploadId;
    } else {
      message.massUploadId = '0';
    }
    if (object.result !== undefined && object.result !== null) {
      message.result = object.result;
    } else {
      message.result = 0;
    }
    return message;
  },
};

const baseGetMassUploadReportRequest: object = { id: '0' };

export const GetMassUploadReportRequest = {
  encode(
    message: GetMassUploadReportRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.id !== '0') {
      writer.uint32(8).int64(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMassUploadReportRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetMassUploadReportRequest } as GetMassUploadReportRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.id = longToString(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetMassUploadReportRequest {
    const message = { ...baseGetMassUploadReportRequest } as GetMassUploadReportRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = String(object.id);
    } else {
      message.id = '0';
    }
    return message;
  },

  toJSON(message: GetMassUploadReportRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  fromPartial(object: DeepPartial<GetMassUploadReportRequest>): GetMassUploadReportRequest {
    const message = { ...baseGetMassUploadReportRequest } as GetMassUploadReportRequest;
    if (object.id !== undefined && object.id !== null) {
      message.id = object.id;
    } else {
      message.id = '0';
    }
    return message;
  },
};

const baseGetMassUploadReportResponse: object = { status: 0, message: '' };

export const GetMassUploadReportResponse = {
  encode(
    message: GetMassUploadReportResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.report !== undefined) {
      MassUploadReport.encode(message.report, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMassUploadReportResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetMassUploadReportResponse } as GetMassUploadReportResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.report = MassUploadReport.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetMassUploadReportResponse {
    const message = { ...baseGetMassUploadReportResponse } as GetMassUploadReportResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.report !== undefined && object.report !== null) {
      message.report = MassUploadReport.fromJSON(object.report);
    } else {
      message.report = undefined;
    }
    return message;
  },

  toJSON(message: GetMassUploadReportResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.report !== undefined &&
      (obj.report = message.report ? MassUploadReport.toJSON(message.report) : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<GetMassUploadReportResponse>): GetMassUploadReportResponse {
    const message = { ...baseGetMassUploadReportResponse } as GetMassUploadReportResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.report !== undefined && object.report !== null) {
      message.report = MassUploadReport.fromPartial(object.report);
    } else {
      message.report = undefined;
    }
    return message;
  },
};

const baseGetAllMassUploadReportsRequest: object = {};

export const GetAllMassUploadReportsRequest = {
  encode(_: GetAllMassUploadReportsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllMassUploadReportsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetAllMassUploadReportsRequest } as GetAllMassUploadReportsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(_: any): GetAllMassUploadReportsRequest {
    const message = { ...baseGetAllMassUploadReportsRequest } as GetAllMassUploadReportsRequest;
    return message;
  },

  toJSON(_: GetAllMassUploadReportsRequest): unknown {
    const obj: any = {};
    return obj;
  },

  fromPartial(_: DeepPartial<GetAllMassUploadReportsRequest>): GetAllMassUploadReportsRequest {
    const message = { ...baseGetAllMassUploadReportsRequest } as GetAllMassUploadReportsRequest;
    return message;
  },
};

const baseGetAllMassUploadReportsResponse: object = { status: 0, message: '' };

export const GetAllMassUploadReportsResponse = {
  encode(
    message: GetAllMassUploadReportsResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.reports) {
      MassUploadReport.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllMassUploadReportsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetAllMassUploadReportsResponse } as GetAllMassUploadReportsResponse;
    message.reports = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.reports.push(MassUploadReport.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAllMassUploadReportsResponse {
    const message = { ...baseGetAllMassUploadReportsResponse } as GetAllMassUploadReportsResponse;
    message.reports = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.reports !== undefined && object.reports !== null) {
      for (const e of object.reports) {
        message.reports.push(MassUploadReport.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: GetAllMassUploadReportsResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.reports) {
      obj.reports = message.reports.map((e) => (e ? MassUploadReport.toJSON(e) : undefined));
    } else {
      obj.reports = [];
    }
    return obj;
  },

  fromPartial(
    object: DeepPartial<GetAllMassUploadReportsResponse>,
  ): GetAllMassUploadReportsResponse {
    const message = { ...baseGetAllMassUploadReportsResponse } as GetAllMassUploadReportsResponse;
    message.reports = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.reports !== undefined && object.reports !== null) {
      for (const e of object.reports) {
        message.reports.push(MassUploadReport.fromPartial(e));
      }
    }
    return message;
  },
};

const baseSearchEmployeesRequest: object = { keyword: '', searchType: 0 };

export const SearchEmployeesRequest = {
  encode(message: SearchEmployeesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.keyword !== '') {
      writer.uint32(10).string(message.keyword);
    }
    if (message.searchType !== 0) {
      writer.uint32(16).int32(message.searchType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchEmployeesRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSearchEmployeesRequest } as SearchEmployeesRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.keyword = reader.string();
          break;
        case 2:
          message.searchType = reader.int32() as any;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SearchEmployeesRequest {
    const message = { ...baseSearchEmployeesRequest } as SearchEmployeesRequest;
    if (object.keyword !== undefined && object.keyword !== null) {
      message.keyword = String(object.keyword);
    } else {
      message.keyword = '';
    }
    if (object.searchType !== undefined && object.searchType !== null) {
      message.searchType = searchEmployeesRequest_SearchEmployeesTypeFromJSON(object.searchType);
    } else {
      message.searchType = 0;
    }
    return message;
  },

  toJSON(message: SearchEmployeesRequest): unknown {
    const obj: any = {};
    message.keyword !== undefined && (obj.keyword = message.keyword);
    message.searchType !== undefined &&
      (obj.searchType = searchEmployeesRequest_SearchEmployeesTypeToJSON(message.searchType));
    return obj;
  },

  fromPartial(object: DeepPartial<SearchEmployeesRequest>): SearchEmployeesRequest {
    const message = { ...baseSearchEmployeesRequest } as SearchEmployeesRequest;
    if (object.keyword !== undefined && object.keyword !== null) {
      message.keyword = object.keyword;
    } else {
      message.keyword = '';
    }
    if (object.searchType !== undefined && object.searchType !== null) {
      message.searchType = object.searchType;
    } else {
      message.searchType = 0;
    }
    return message;
  },
};

const baseSearchEmployeesResponse: object = { status: 0, message: '' };

export const SearchEmployeesResponse = {
  encode(message: SearchEmployeesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.employees) {
      EmployeeFound.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchEmployeesResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseSearchEmployeesResponse } as SearchEmployeesResponse;
    message.employees = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.employees.push(EmployeeFound.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): SearchEmployeesResponse {
    const message = { ...baseSearchEmployeesResponse } as SearchEmployeesResponse;
    message.employees = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.employees !== undefined && object.employees !== null) {
      for (const e of object.employees) {
        message.employees.push(EmployeeFound.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: SearchEmployeesResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.employees) {
      obj.employees = message.employees.map((e) => (e ? EmployeeFound.toJSON(e) : undefined));
    } else {
      obj.employees = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<SearchEmployeesResponse>): SearchEmployeesResponse {
    const message = { ...baseSearchEmployeesResponse } as SearchEmployeesResponse;
    message.employees = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.employees !== undefined && object.employees !== null) {
      for (const e of object.employees) {
        message.employees.push(EmployeeFound.fromPartial(e));
      }
    }
    return message;
  },
};

const baseCreateCompanyEventRequest: object = { type: 0 };

export const CreateCompanyEventRequest = {
  encode(message: CreateCompanyEventRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.name !== undefined) {
      StringValue.encode({ value: message.name! }, writer.uint32(18).fork()).ldelim();
    }
    if (message.purpose !== undefined) {
      StringValue.encode({ value: message.purpose! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.comments !== undefined) {
      StringValue.encode({ value: message.comments! }, writer.uint32(34).fork()).ldelim();
    }
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(42).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(50).fork()).ldelim();
    }
    if (message.locationId !== undefined) {
      Int64Value.encode({ value: message.locationId! }, writer.uint32(58).fork()).ldelim();
    }
    if (message.host !== undefined) {
      Int64Value.encode({ value: message.host! }, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.planners) {
      Int64Value.encode({ value: v!! }, writer.uint32(74).fork()).ldelim();
    }
    if (message.officeId !== undefined) {
      Int64Value.encode({ value: message.officeId! }, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCompanyEventRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCreateCompanyEventRequest } as CreateCompanyEventRequest;
    message.planners = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.type = reader.int32() as any;
          break;
        case 2:
          message.name = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 3:
          message.purpose = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.comments = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 5:
          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 6:
          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 7:
          message.locationId = Int64Value.decode(reader, reader.uint32()).value;
          break;
        case 8:
          message.host = Int64Value.decode(reader, reader.uint32()).value;
          break;
        case 9:
          message.planners.push(Int64Value.decode(reader, reader.uint32()).value);
          break;
        case 10:
          message.officeId = Int64Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateCompanyEventRequest {
    const message = { ...baseCreateCompanyEventRequest } as CreateCompanyEventRequest;
    message.planners = [];
    if (object.type !== undefined && object.type !== null) {
      message.type = companyEventTypeFromJSON(object.type);
    } else {
      message.type = 0;
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = String(object.name);
    } else {
      message.name = undefined;
    }
    if (object.purpose !== undefined && object.purpose !== null) {
      message.purpose = String(object.purpose);
    } else {
      message.purpose = undefined;
    }
    if (object.comments !== undefined && object.comments !== null) {
      message.comments = String(object.comments);
    } else {
      message.comments = undefined;
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = fromJsonTimestamp(object.startDate);
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = fromJsonTimestamp(object.endDate);
    } else {
      message.endDate = undefined;
    }
    if (object.locationId !== undefined && object.locationId !== null) {
      message.locationId = String(object.locationId);
    } else {
      message.locationId = undefined;
    }
    if (object.host !== undefined && object.host !== null) {
      message.host = String(object.host);
    } else {
      message.host = undefined;
    }
    if (object.planners !== undefined && object.planners !== null) {
      for (const e of object.planners) {
        message.planners.push(String(e));
      }
    }
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = String(object.officeId);
    } else {
      message.officeId = undefined;
    }
    return message;
  },

  toJSON(message: CreateCompanyEventRequest): unknown {
    const obj: any = {};
    message.type !== undefined && (obj.type = companyEventTypeToJSON(message.type));
    message.name !== undefined && (obj.name = message.name);
    message.purpose !== undefined && (obj.purpose = message.purpose);
    message.comments !== undefined && (obj.comments = message.comments);
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    message.locationId !== undefined && (obj.locationId = message.locationId);
    message.host !== undefined && (obj.host = message.host);
    if (message.planners) {
      obj.planners = message.planners.map((e) => e);
    } else {
      obj.planners = [];
    }
    message.officeId !== undefined && (obj.officeId = message.officeId);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateCompanyEventRequest>): CreateCompanyEventRequest {
    const message = { ...baseCreateCompanyEventRequest } as CreateCompanyEventRequest;
    message.planners = [];
    if (object.type !== undefined && object.type !== null) {
      message.type = object.type;
    } else {
      message.type = 0;
    }
    if (object.name !== undefined && object.name !== null) {
      message.name = object.name;
    } else {
      message.name = undefined;
    }
    if (object.purpose !== undefined && object.purpose !== null) {
      message.purpose = object.purpose;
    } else {
      message.purpose = undefined;
    }
    if (object.comments !== undefined && object.comments !== null) {
      message.comments = object.comments;
    } else {
      message.comments = undefined;
    }
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = undefined;
    }
    if (object.locationId !== undefined && object.locationId !== null) {
      message.locationId = object.locationId;
    } else {
      message.locationId = undefined;
    }
    if (object.host !== undefined && object.host !== null) {
      message.host = object.host;
    } else {
      message.host = undefined;
    }
    if (object.planners !== undefined && object.planners !== null) {
      for (const e of object.planners) {
        message.planners.push(e);
      }
    }
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = object.officeId;
    } else {
      message.officeId = undefined;
    }
    return message;
  },
};

const baseCreateCompanyEventResponse: object = { status: 0, message: '', eventId: '0' };

export const CreateCompanyEventResponse = {
  encode(
    message: CreateCompanyEventResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.eventId !== '0') {
      writer.uint32(24).int64(message.eventId);
    }
    if (message.rejectedVisits !== undefined) {
      RejectedBooking.encode(message.rejectedVisits, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCompanyEventResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCreateCompanyEventResponse } as CreateCompanyEventResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.eventId = longToString(reader.int64() as Long);
          break;
        case 4:
          message.rejectedVisits = RejectedBooking.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CreateCompanyEventResponse {
    const message = { ...baseCreateCompanyEventResponse } as CreateCompanyEventResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = String(object.eventId);
    } else {
      message.eventId = '0';
    }
    if (object.rejectedVisits !== undefined && object.rejectedVisits !== null) {
      message.rejectedVisits = RejectedBooking.fromJSON(object.rejectedVisits);
    } else {
      message.rejectedVisits = undefined;
    }
    return message;
  },

  toJSON(message: CreateCompanyEventResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.eventId !== undefined && (obj.eventId = message.eventId);
    message.rejectedVisits !== undefined &&
      (obj.rejectedVisits = message.rejectedVisits
        ? RejectedBooking.toJSON(message.rejectedVisits)
        : undefined);
    return obj;
  },

  fromPartial(object: DeepPartial<CreateCompanyEventResponse>): CreateCompanyEventResponse {
    const message = { ...baseCreateCompanyEventResponse } as CreateCompanyEventResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = object.eventId;
    } else {
      message.eventId = '0';
    }
    if (object.rejectedVisits !== undefined && object.rejectedVisits !== null) {
      message.rejectedVisits = RejectedBooking.fromPartial(object.rejectedVisits);
    } else {
      message.rejectedVisits = undefined;
    }
    return message;
  },
};

const baseInviteAttendeesForCompanyEventRequest: object = { eventId: '0', attendeeIds: '0' };

export const InviteAttendeesForCompanyEventRequest = {
  encode(
    message: InviteAttendeesForCompanyEventRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.eventId !== '0') {
      writer.uint32(8).int64(message.eventId);
    }
    writer.uint32(18).fork();
    for (const v of message.attendeeIds) {
      writer.int64(v);
    }
    writer.ldelim();
    if (message.message !== undefined) {
      StringValue.encode({ value: message.message! }, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteAttendeesForCompanyEventRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseInviteAttendeesForCompanyEventRequest,
    } as InviteAttendeesForCompanyEventRequest;
    message.attendeeIds = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.eventId = longToString(reader.int64() as Long);
          break;
        case 2:
          if ((tag & 7) === 2) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.attendeeIds.push(longToString(reader.int64() as Long));
            }
          } else {
            message.attendeeIds.push(longToString(reader.int64() as Long));
          }
          break;
        case 3:
          message.message = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InviteAttendeesForCompanyEventRequest {
    const message = {
      ...baseInviteAttendeesForCompanyEventRequest,
    } as InviteAttendeesForCompanyEventRequest;
    message.attendeeIds = [];
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = String(object.eventId);
    } else {
      message.eventId = '0';
    }
    if (object.attendeeIds !== undefined && object.attendeeIds !== null) {
      for (const e of object.attendeeIds) {
        message.attendeeIds.push(String(e));
      }
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = undefined;
    }
    return message;
  },

  toJSON(message: InviteAttendeesForCompanyEventRequest): unknown {
    const obj: any = {};
    message.eventId !== undefined && (obj.eventId = message.eventId);
    if (message.attendeeIds) {
      obj.attendeeIds = message.attendeeIds.map((e) => e);
    } else {
      obj.attendeeIds = [];
    }
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  fromPartial(
    object: DeepPartial<InviteAttendeesForCompanyEventRequest>,
  ): InviteAttendeesForCompanyEventRequest {
    const message = {
      ...baseInviteAttendeesForCompanyEventRequest,
    } as InviteAttendeesForCompanyEventRequest;
    message.attendeeIds = [];
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = object.eventId;
    } else {
      message.eventId = '0';
    }
    if (object.attendeeIds !== undefined && object.attendeeIds !== null) {
      for (const e of object.attendeeIds) {
        message.attendeeIds.push(e);
      }
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = undefined;
    }
    return message;
  },
};

const baseInviteAttendeesForCompanyEventResponse: object = { status: 0, message: '' };

export const InviteAttendeesForCompanyEventResponse = {
  encode(
    message: InviteAttendeesForCompanyEventResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): InviteAttendeesForCompanyEventResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = {
      ...baseInviteAttendeesForCompanyEventResponse,
    } as InviteAttendeesForCompanyEventResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): InviteAttendeesForCompanyEventResponse {
    const message = {
      ...baseInviteAttendeesForCompanyEventResponse,
    } as InviteAttendeesForCompanyEventResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    return message;
  },

  toJSON(message: InviteAttendeesForCompanyEventResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  fromPartial(
    object: DeepPartial<InviteAttendeesForCompanyEventResponse>,
  ): InviteAttendeesForCompanyEventResponse {
    const message = {
      ...baseInviteAttendeesForCompanyEventResponse,
    } as InviteAttendeesForCompanyEventResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    return message;
  },
};

const baseGetCompanyEventRequest: object = { eventId: '0' };

export const GetCompanyEventRequest = {
  encode(message: GetCompanyEventRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.eventId !== '0') {
      writer.uint32(8).int64(message.eventId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCompanyEventRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetCompanyEventRequest } as GetCompanyEventRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.eventId = longToString(reader.int64() as Long);
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetCompanyEventRequest {
    const message = { ...baseGetCompanyEventRequest } as GetCompanyEventRequest;
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = String(object.eventId);
    } else {
      message.eventId = '0';
    }
    return message;
  },

  toJSON(message: GetCompanyEventRequest): unknown {
    const obj: any = {};
    message.eventId !== undefined && (obj.eventId = message.eventId);
    return obj;
  },

  fromPartial(object: DeepPartial<GetCompanyEventRequest>): GetCompanyEventRequest {
    const message = { ...baseGetCompanyEventRequest } as GetCompanyEventRequest;
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = object.eventId;
    } else {
      message.eventId = '0';
    }
    return message;
  },
};

const baseGetCompanyEventResponse: object = { status: 0, message: '' };

export const GetCompanyEventResponse = {
  encode(message: GetCompanyEventResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.event !== undefined) {
      CompanyEvent.encode(message.event, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.users) {
      CompanyEventUser.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCompanyEventResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetCompanyEventResponse } as GetCompanyEventResponse;
    message.users = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.event = CompanyEvent.decode(reader, reader.uint32());
          break;
        case 4:
          message.users.push(CompanyEventUser.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetCompanyEventResponse {
    const message = { ...baseGetCompanyEventResponse } as GetCompanyEventResponse;
    message.users = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.event !== undefined && object.event !== null) {
      message.event = CompanyEvent.fromJSON(object.event);
    } else {
      message.event = undefined;
    }
    if (object.users !== undefined && object.users !== null) {
      for (const e of object.users) {
        message.users.push(CompanyEventUser.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: GetCompanyEventResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.event !== undefined &&
      (obj.event = message.event ? CompanyEvent.toJSON(message.event) : undefined);
    if (message.users) {
      obj.users = message.users.map((e) => (e ? CompanyEventUser.toJSON(e) : undefined));
    } else {
      obj.users = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetCompanyEventResponse>): GetCompanyEventResponse {
    const message = { ...baseGetCompanyEventResponse } as GetCompanyEventResponse;
    message.users = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.event !== undefined && object.event !== null) {
      message.event = CompanyEvent.fromPartial(object.event);
    } else {
      message.event = undefined;
    }
    if (object.users !== undefined && object.users !== null) {
      for (const e of object.users) {
        message.users.push(CompanyEventUser.fromPartial(e));
      }
    }
    return message;
  },
};

const baseGetCompanyEventsRequest: object = {};

export const GetCompanyEventsRequest = {
  encode(message: GetCompanyEventsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCompanyEventsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetCompanyEventsRequest } as GetCompanyEventsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 2:
          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetCompanyEventsRequest {
    const message = { ...baseGetCompanyEventsRequest } as GetCompanyEventsRequest;
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = fromJsonTimestamp(object.startDate);
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = fromJsonTimestamp(object.endDate);
    } else {
      message.endDate = undefined;
    }
    return message;
  },

  toJSON(message: GetCompanyEventsRequest): unknown {
    const obj: any = {};
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<GetCompanyEventsRequest>): GetCompanyEventsRequest {
    const message = { ...baseGetCompanyEventsRequest } as GetCompanyEventsRequest;
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = undefined;
    }
    return message;
  },
};

const baseGetCompanyEventsResponse: object = { status: 0, message: '' };

export const GetCompanyEventsResponse = {
  encode(message: GetCompanyEventsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.events) {
      CompanyEvent.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCompanyEventsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetCompanyEventsResponse } as GetCompanyEventsResponse;
    message.events = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.events.push(CompanyEvent.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetCompanyEventsResponse {
    const message = { ...baseGetCompanyEventsResponse } as GetCompanyEventsResponse;
    message.events = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.events !== undefined && object.events !== null) {
      for (const e of object.events) {
        message.events.push(CompanyEvent.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: GetCompanyEventsResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.events) {
      obj.events = message.events.map((e) => (e ? CompanyEvent.toJSON(e) : undefined));
    } else {
      obj.events = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetCompanyEventsResponse>): GetCompanyEventsResponse {
    const message = { ...baseGetCompanyEventsResponse } as GetCompanyEventsResponse;
    message.events = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.events !== undefined && object.events !== null) {
      for (const e of object.events) {
        message.events.push(CompanyEvent.fromPartial(e));
      }
    }
    return message;
  },
};

const baseCancelCompanyEventRequest: object = { eventId: '0' };

export const CancelCompanyEventRequest = {
  encode(message: CancelCompanyEventRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.eventId !== '0') {
      writer.uint32(8).int64(message.eventId);
    }
    if (message.reason !== undefined) {
      StringValue.encode({ value: message.reason! }, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelCompanyEventRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCancelCompanyEventRequest } as CancelCompanyEventRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.eventId = longToString(reader.int64() as Long);
          break;
        case 2:
          message.reason = StringValue.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CancelCompanyEventRequest {
    const message = { ...baseCancelCompanyEventRequest } as CancelCompanyEventRequest;
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = String(object.eventId);
    } else {
      message.eventId = '0';
    }
    if (object.reason !== undefined && object.reason !== null) {
      message.reason = String(object.reason);
    } else {
      message.reason = undefined;
    }
    return message;
  },

  toJSON(message: CancelCompanyEventRequest): unknown {
    const obj: any = {};
    message.eventId !== undefined && (obj.eventId = message.eventId);
    message.reason !== undefined && (obj.reason = message.reason);
    return obj;
  },

  fromPartial(object: DeepPartial<CancelCompanyEventRequest>): CancelCompanyEventRequest {
    const message = { ...baseCancelCompanyEventRequest } as CancelCompanyEventRequest;
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = object.eventId;
    } else {
      message.eventId = '0';
    }
    if (object.reason !== undefined && object.reason !== null) {
      message.reason = object.reason;
    } else {
      message.reason = undefined;
    }
    return message;
  },
};

const baseCancelCompanyEventResponse: object = { status: 0, message: '' };

export const CancelCompanyEventResponse = {
  encode(
    message: CancelCompanyEventResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CancelCompanyEventResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseCancelCompanyEventResponse } as CancelCompanyEventResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): CancelCompanyEventResponse {
    const message = { ...baseCancelCompanyEventResponse } as CancelCompanyEventResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    return message;
  },

  toJSON(message: CancelCompanyEventResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    return obj;
  },

  fromPartial(object: DeepPartial<CancelCompanyEventResponse>): CancelCompanyEventResponse {
    const message = { ...baseCancelCompanyEventResponse } as CancelCompanyEventResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    return message;
  },
};

const baseAcknowledgeCompanyEventRequest: object = { eventId: '0', status: 0 };

export const AcknowledgeCompanyEventRequest = {
  encode(
    message: AcknowledgeCompanyEventRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.eventId !== '0') {
      writer.uint32(8).int64(message.eventId);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.comments !== undefined) {
      StringValue.encode({ value: message.comments! }, writer.uint32(26).fork()).ldelim();
    }
    if (message.officeId !== undefined) {
      Int64Value.encode({ value: message.officeId! }, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcknowledgeCompanyEventRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAcknowledgeCompanyEventRequest } as AcknowledgeCompanyEventRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.eventId = longToString(reader.int64() as Long);
          break;
        case 2:
          message.status = reader.int32() as any;
          break;
        case 3:
          message.comments = StringValue.decode(reader, reader.uint32()).value;
          break;
        case 4:
          message.officeId = Int64Value.decode(reader, reader.uint32()).value;
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AcknowledgeCompanyEventRequest {
    const message = { ...baseAcknowledgeCompanyEventRequest } as AcknowledgeCompanyEventRequest;
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = String(object.eventId);
    } else {
      message.eventId = '0';
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = companyEventUserStatusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.comments !== undefined && object.comments !== null) {
      message.comments = String(object.comments);
    } else {
      message.comments = undefined;
    }
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = String(object.officeId);
    } else {
      message.officeId = undefined;
    }
    return message;
  },

  toJSON(message: AcknowledgeCompanyEventRequest): unknown {
    const obj: any = {};
    message.eventId !== undefined && (obj.eventId = message.eventId);
    message.status !== undefined && (obj.status = companyEventUserStatusTypeToJSON(message.status));
    message.comments !== undefined && (obj.comments = message.comments);
    message.officeId !== undefined && (obj.officeId = message.officeId);
    return obj;
  },

  fromPartial(object: DeepPartial<AcknowledgeCompanyEventRequest>): AcknowledgeCompanyEventRequest {
    const message = { ...baseAcknowledgeCompanyEventRequest } as AcknowledgeCompanyEventRequest;
    if (object.eventId !== undefined && object.eventId !== null) {
      message.eventId = object.eventId;
    } else {
      message.eventId = '0';
    }
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.comments !== undefined && object.comments !== null) {
      message.comments = object.comments;
    } else {
      message.comments = undefined;
    }
    if (object.officeId !== undefined && object.officeId !== null) {
      message.officeId = object.officeId;
    } else {
      message.officeId = undefined;
    }
    return message;
  },
};

const baseAcknowledgeCompanyEventResponse: object = { status: 0, message: '', resultType: 0 };

export const AcknowledgeCompanyEventResponse = {
  encode(
    message: AcknowledgeCompanyEventResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    if (message.resultType !== 0) {
      writer.uint32(24).int32(message.resultType);
    }
    if (message.rejectedVisits !== undefined) {
      RejectedBooking.encode(message.rejectedVisits, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AcknowledgeCompanyEventResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseAcknowledgeCompanyEventResponse } as AcknowledgeCompanyEventResponse;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.resultType = reader.int32() as any;
          break;
        case 4:
          message.rejectedVisits = RejectedBooking.decode(reader, reader.uint32());
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): AcknowledgeCompanyEventResponse {
    const message = { ...baseAcknowledgeCompanyEventResponse } as AcknowledgeCompanyEventResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.resultType !== undefined && object.resultType !== null) {
      message.resultType = acknowledgeCompanyEventResponseTypeFromJSON(object.resultType);
    } else {
      message.resultType = 0;
    }
    if (object.rejectedVisits !== undefined && object.rejectedVisits !== null) {
      message.rejectedVisits = RejectedBooking.fromJSON(object.rejectedVisits);
    } else {
      message.rejectedVisits = undefined;
    }
    return message;
  },

  toJSON(message: AcknowledgeCompanyEventResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    message.resultType !== undefined &&
      (obj.resultType = acknowledgeCompanyEventResponseTypeToJSON(message.resultType));
    message.rejectedVisits !== undefined &&
      (obj.rejectedVisits = message.rejectedVisits
        ? RejectedBooking.toJSON(message.rejectedVisits)
        : undefined);
    return obj;
  },

  fromPartial(
    object: DeepPartial<AcknowledgeCompanyEventResponse>,
  ): AcknowledgeCompanyEventResponse {
    const message = { ...baseAcknowledgeCompanyEventResponse } as AcknowledgeCompanyEventResponse;
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.resultType !== undefined && object.resultType !== null) {
      message.resultType = object.resultType;
    } else {
      message.resultType = 0;
    }
    if (object.rejectedVisits !== undefined && object.rejectedVisits !== null) {
      message.rejectedVisits = RejectedBooking.fromPartial(object.rejectedVisits);
    } else {
      message.rejectedVisits = undefined;
    }
    return message;
  },
};

const baseGetAvailableLocationsRequest: object = {};

export const GetAvailableLocationsRequest = {
  encode(
    message: GetAvailableLocationsRequest,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.startDate !== undefined) {
      Timestamp.encode(toTimestamp(message.startDate), writer.uint32(10).fork()).ldelim();
    }
    if (message.endDate !== undefined) {
      Timestamp.encode(toTimestamp(message.endDate), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAvailableLocationsRequest {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetAvailableLocationsRequest } as GetAvailableLocationsRequest;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.startDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        case 2:
          message.endDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAvailableLocationsRequest {
    const message = { ...baseGetAvailableLocationsRequest } as GetAvailableLocationsRequest;
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = fromJsonTimestamp(object.startDate);
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = fromJsonTimestamp(object.endDate);
    } else {
      message.endDate = undefined;
    }
    return message;
  },

  toJSON(message: GetAvailableLocationsRequest): unknown {
    const obj: any = {};
    message.startDate !== undefined && (obj.startDate = message.startDate.toISOString());
    message.endDate !== undefined && (obj.endDate = message.endDate.toISOString());
    return obj;
  },

  fromPartial(object: DeepPartial<GetAvailableLocationsRequest>): GetAvailableLocationsRequest {
    const message = { ...baseGetAvailableLocationsRequest } as GetAvailableLocationsRequest;
    if (object.startDate !== undefined && object.startDate !== null) {
      message.startDate = object.startDate;
    } else {
      message.startDate = undefined;
    }
    if (object.endDate !== undefined && object.endDate !== null) {
      message.endDate = object.endDate;
    } else {
      message.endDate = undefined;
    }
    return message;
  },
};

const baseGetAvailableLocationsResponse: object = { status: 0, message: '' };

export const GetAvailableLocationsResponse = {
  encode(
    message: GetAvailableLocationsResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.message !== '') {
      writer.uint32(18).string(message.message);
    }
    for (const v of message.locations) {
      EventLocation.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAvailableLocationsResponse {
    const reader = input instanceof _m0.Reader ? input : new _m0.Reader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = { ...baseGetAvailableLocationsResponse } as GetAvailableLocationsResponse;
    message.locations = [];
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          message.status = reader.int32() as any;
          break;
        case 2:
          message.message = reader.string();
          break;
        case 3:
          message.locations.push(EventLocation.decode(reader, reader.uint32()));
          break;
        default:
          reader.skipType(tag & 7);
          break;
      }
    }
    return message;
  },

  fromJSON(object: any): GetAvailableLocationsResponse {
    const message = { ...baseGetAvailableLocationsResponse } as GetAvailableLocationsResponse;
    message.locations = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = statusTypeFromJSON(object.status);
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = String(object.message);
    } else {
      message.message = '';
    }
    if (object.locations !== undefined && object.locations !== null) {
      for (const e of object.locations) {
        message.locations.push(EventLocation.fromJSON(e));
      }
    }
    return message;
  },

  toJSON(message: GetAvailableLocationsResponse): unknown {
    const obj: any = {};
    message.status !== undefined && (obj.status = statusTypeToJSON(message.status));
    message.message !== undefined && (obj.message = message.message);
    if (message.locations) {
      obj.locations = message.locations.map((e) => (e ? EventLocation.toJSON(e) : undefined));
    } else {
      obj.locations = [];
    }
    return obj;
  },

  fromPartial(object: DeepPartial<GetAvailableLocationsResponse>): GetAvailableLocationsResponse {
    const message = { ...baseGetAvailableLocationsResponse } as GetAvailableLocationsResponse;
    message.locations = [];
    if (object.status !== undefined && object.status !== null) {
      message.status = object.status;
    } else {
      message.status = 0;
    }
    if (object.message !== undefined && object.message !== null) {
      message.message = object.message;
    } else {
      message.message = '';
    }
    if (object.locations !== undefined && object.locations !== null) {
      for (const e of object.locations) {
        message.locations.push(EventLocation.fromPartial(e));
      }
    }
    return message;
  },
};

export interface HQWeb {
  /** hq user */
  GetUserInfo(request: GetUserInfoRequest): Promise<GetUserInfoResponse>;
  GetMyImpersonatees(request: GetMyImpersonateesRequest): Promise<GetMyImpersonateesResponse>;
  GetAllOffices(request: GetAllOfficesRequest): Promise<GetAllOfficesResponse>;
  GetAllOfficeFloors(request: GetAllOfficeFloorsRequest): Promise<GetAllOfficeFloorsResponse>;
  GetOfficeBlockedDates(
    request: GetOfficeBlockedDatesRequest,
  ): Promise<GetOfficeBlockedDatesResponse>;
  GetAllOfficesBlockedDates(
    request: GetAllOfficesBlockedDatesRequest,
  ): Promise<GetAllOfficesBlockedDatesResponse>;
  GetFullyBookedDates(request: GetFullyBookedDatesRequest): Promise<GetFullyBookedDatesResponse>;
  GetOfficeUsages(request: GetOfficeUsagesRequest): Promise<GetOfficeUsagesResponse>;
  GetEmployeeVisits(request: GetEmployeeVisitsRequest): Promise<GetEmployeeVisitsResponse>;
  BookOfficeVisit(request: BookOfficeVisitRequest): Promise<BookOfficeVisitResponse>;
  CancelOfficeVisit(request: CancelOfficeVisitRequest): Promise<CancelOfficeVisitResponse>;
  GetHATTStatus(request: GetHATTStatusRequest): Promise<GetHATTStatusResponse>;
  GetVaccinationStatus(request: GetVaccinationStatusRequest): Promise<GetVaccinationStatusResponse>;
  GetTrainingStatus(request: GetTrainingStatusRequest): Promise<GetTrainingStatusResponse>;
  IsBadgeExempt(request: IsBadgeExemptRequest): Promise<IsBadgeExemptResponse>;
  GetSystemHealth(request: GetSystemHealthRequest): Promise<GetSystemHealthResponse>;
  GetUserInboxMessages(request: GetUserInboxMessagesRequest): Promise<GetUserInboxMessagesResponse>;
  AcknowledgeUserInboxMessage(
    request: AcknowledgeUserInboxMessageRequest,
  ): Promise<AcknowledgeUserInboxMessageResponse>;
  /** mass upload */
  UploadOfficeVisitsInBulk(
    request: UploadOfficeVisitsInBulkRequest,
  ): Promise<UploadOfficeVisitsInBulkResponse>;
  GetMassUploadReport(request: GetMassUploadReportRequest): Promise<GetMassUploadReportResponse>;
  GetAllMassUploadReports(
    request: GetAllMassUploadReportsRequest,
  ): Promise<GetAllMassUploadReportsResponse>;
  /** search */
  SearchEmployees(request: SearchEmployeesRequest): Promise<SearchEmployeesResponse>;
  /** company event */
  CreateCompanyEvent(request: CreateCompanyEventRequest): Promise<CreateCompanyEventResponse>;
  InviteAttendeesForCompanyEvent(
    request: InviteAttendeesForCompanyEventRequest,
  ): Promise<InviteAttendeesForCompanyEventResponse>;
  GetCompanyEvent(request: GetCompanyEventRequest): Promise<GetCompanyEventResponse>;
  GetCompanyEvents(request: GetCompanyEventsRequest): Promise<GetCompanyEventsResponse>;
  CancelCompanyEvent(request: CancelCompanyEventRequest): Promise<CancelCompanyEventResponse>;
  AcknowledgeCompanyEvent(
    request: AcknowledgeCompanyEventRequest,
  ): Promise<AcknowledgeCompanyEventResponse>;
  /** location */
  GetAvailableLocations(
    request: GetAvailableLocationsRequest,
  ): Promise<GetAvailableLocationsResponse>;
}

export class HQWebClientImpl implements HQWeb {
  private readonly rpc: Rpc;
  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetUserInfo = this.GetUserInfo.bind(this);
    this.GetMyImpersonatees = this.GetMyImpersonatees.bind(this);
    this.GetAllOffices = this.GetAllOffices.bind(this);
    this.GetAllOfficeFloors = this.GetAllOfficeFloors.bind(this);
    this.GetOfficeBlockedDates = this.GetOfficeBlockedDates.bind(this);
    this.GetAllOfficesBlockedDates = this.GetAllOfficesBlockedDates.bind(this);
    this.GetFullyBookedDates = this.GetFullyBookedDates.bind(this);
    this.GetOfficeUsages = this.GetOfficeUsages.bind(this);
    this.GetEmployeeVisits = this.GetEmployeeVisits.bind(this);
    this.BookOfficeVisit = this.BookOfficeVisit.bind(this);
    this.CancelOfficeVisit = this.CancelOfficeVisit.bind(this);
    this.GetHATTStatus = this.GetHATTStatus.bind(this);
    this.GetVaccinationStatus = this.GetVaccinationStatus.bind(this);
    this.GetTrainingStatus = this.GetTrainingStatus.bind(this);
    this.IsBadgeExempt = this.IsBadgeExempt.bind(this);
    this.GetSystemHealth = this.GetSystemHealth.bind(this);
    this.GetUserInboxMessages = this.GetUserInboxMessages.bind(this);
    this.AcknowledgeUserInboxMessage = this.AcknowledgeUserInboxMessage.bind(this);
    this.UploadOfficeVisitsInBulk = this.UploadOfficeVisitsInBulk.bind(this);
    this.GetMassUploadReport = this.GetMassUploadReport.bind(this);
    this.GetAllMassUploadReports = this.GetAllMassUploadReports.bind(this);
    this.SearchEmployees = this.SearchEmployees.bind(this);
    this.CreateCompanyEvent = this.CreateCompanyEvent.bind(this);
    this.InviteAttendeesForCompanyEvent = this.InviteAttendeesForCompanyEvent.bind(this);
    this.GetCompanyEvent = this.GetCompanyEvent.bind(this);
    this.GetCompanyEvents = this.GetCompanyEvents.bind(this);
    this.CancelCompanyEvent = this.CancelCompanyEvent.bind(this);
    this.AcknowledgeCompanyEvent = this.AcknowledgeCompanyEvent.bind(this);
    this.GetAvailableLocations = this.GetAvailableLocations.bind(this);
  }
  GetUserInfo(request: GetUserInfoRequest): Promise<GetUserInfoResponse> {
    const data = GetUserInfoRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetUserInfo', data);
    return promise.then((data) => GetUserInfoResponse.decode(new _m0.Reader(data)));
  }

  GetMyImpersonatees(request: GetMyImpersonateesRequest): Promise<GetMyImpersonateesResponse> {
    const data = GetMyImpersonateesRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetMyImpersonatees', data);
    return promise.then((data) => GetMyImpersonateesResponse.decode(new _m0.Reader(data)));
  }

  GetAllOffices(request: GetAllOfficesRequest): Promise<GetAllOfficesResponse> {
    const data = GetAllOfficesRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetAllOffices', data);
    return promise.then((data) => GetAllOfficesResponse.decode(new _m0.Reader(data)));
  }

  GetAllOfficeFloors(request: GetAllOfficeFloorsRequest): Promise<GetAllOfficeFloorsResponse> {
    const data = GetAllOfficeFloorsRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetAllOfficeFloors', data);
    return promise.then((data) => GetAllOfficeFloorsResponse.decode(new _m0.Reader(data)));
  }

  GetOfficeBlockedDates(
    request: GetOfficeBlockedDatesRequest,
  ): Promise<GetOfficeBlockedDatesResponse> {
    const data = GetOfficeBlockedDatesRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetOfficeBlockedDates', data);
    return promise.then((data) => GetOfficeBlockedDatesResponse.decode(new _m0.Reader(data)));
  }

  GetAllOfficesBlockedDates(
    request: GetAllOfficesBlockedDatesRequest,
  ): Promise<GetAllOfficesBlockedDatesResponse> {
    const data = GetAllOfficesBlockedDatesRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetAllOfficesBlockedDates', data);
    return promise.then((data) => GetAllOfficesBlockedDatesResponse.decode(new _m0.Reader(data)));
  }

  GetFullyBookedDates(request: GetFullyBookedDatesRequest): Promise<GetFullyBookedDatesResponse> {
    const data = GetFullyBookedDatesRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetFullyBookedDates', data);
    return promise.then((data) => GetFullyBookedDatesResponse.decode(new _m0.Reader(data)));
  }

  GetOfficeUsages(request: GetOfficeUsagesRequest): Promise<GetOfficeUsagesResponse> {
    const data = GetOfficeUsagesRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetOfficeUsages', data);
    return promise.then((data) => GetOfficeUsagesResponse.decode(new _m0.Reader(data)));
  }

  GetEmployeeVisits(request: GetEmployeeVisitsRequest): Promise<GetEmployeeVisitsResponse> {
    const data = GetEmployeeVisitsRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetEmployeeVisits', data);
    return promise.then((data) => GetEmployeeVisitsResponse.decode(new _m0.Reader(data)));
  }

  BookOfficeVisit(request: BookOfficeVisitRequest): Promise<BookOfficeVisitResponse> {
    const data = BookOfficeVisitRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'BookOfficeVisit', data);
    return promise.then((data) => BookOfficeVisitResponse.decode(new _m0.Reader(data)));
  }

  CancelOfficeVisit(request: CancelOfficeVisitRequest): Promise<CancelOfficeVisitResponse> {
    const data = CancelOfficeVisitRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'CancelOfficeVisit', data);
    return promise.then((data) => CancelOfficeVisitResponse.decode(new _m0.Reader(data)));
  }

  GetHATTStatus(request: GetHATTStatusRequest): Promise<GetHATTStatusResponse> {
    const data = GetHATTStatusRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetHATTStatus', data);
    return promise.then((data) => GetHATTStatusResponse.decode(new _m0.Reader(data)));
  }

  GetVaccinationStatus(
    request: GetVaccinationStatusRequest,
  ): Promise<GetVaccinationStatusResponse> {
    const data = GetVaccinationStatusRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetVaccinationStatus', data);
    return promise.then((data) => GetVaccinationStatusResponse.decode(new _m0.Reader(data)));
  }

  GetTrainingStatus(request: GetTrainingStatusRequest): Promise<GetTrainingStatusResponse> {
    const data = GetTrainingStatusRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetTrainingStatus', data);
    return promise.then((data) => GetTrainingStatusResponse.decode(new _m0.Reader(data)));
  }

  IsBadgeExempt(request: IsBadgeExemptRequest): Promise<IsBadgeExemptResponse> {
    const data = IsBadgeExemptRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'IsBadgeExempt', data);
    return promise.then((data) => IsBadgeExemptResponse.decode(new _m0.Reader(data)));
  }

  GetSystemHealth(request: GetSystemHealthRequest): Promise<GetSystemHealthResponse> {
    const data = GetSystemHealthRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetSystemHealth', data);
    return promise.then((data) => GetSystemHealthResponse.decode(new _m0.Reader(data)));
  }

  GetUserInboxMessages(
    request: GetUserInboxMessagesRequest,
  ): Promise<GetUserInboxMessagesResponse> {
    const data = GetUserInboxMessagesRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetUserInboxMessages', data);
    return promise.then((data) => GetUserInboxMessagesResponse.decode(new _m0.Reader(data)));
  }

  AcknowledgeUserInboxMessage(
    request: AcknowledgeUserInboxMessageRequest,
  ): Promise<AcknowledgeUserInboxMessageResponse> {
    const data = AcknowledgeUserInboxMessageRequest.encode(request).finish();
    const promise = this.rpc.request(
      'peng_hqengine_contract.HQWeb',
      'AcknowledgeUserInboxMessage',
      data,
    );
    return promise.then((data) => AcknowledgeUserInboxMessageResponse.decode(new _m0.Reader(data)));
  }

  UploadOfficeVisitsInBulk(
    request: UploadOfficeVisitsInBulkRequest,
  ): Promise<UploadOfficeVisitsInBulkResponse> {
    const data = UploadOfficeVisitsInBulkRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'UploadOfficeVisitsInBulk', data);
    return promise.then((data) => UploadOfficeVisitsInBulkResponse.decode(new _m0.Reader(data)));
  }

  GetMassUploadReport(request: GetMassUploadReportRequest): Promise<GetMassUploadReportResponse> {
    const data = GetMassUploadReportRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetMassUploadReport', data);
    return promise.then((data) => GetMassUploadReportResponse.decode(new _m0.Reader(data)));
  }

  GetAllMassUploadReports(
    request: GetAllMassUploadReportsRequest,
  ): Promise<GetAllMassUploadReportsResponse> {
    const data = GetAllMassUploadReportsRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetAllMassUploadReports', data);
    return promise.then((data) => GetAllMassUploadReportsResponse.decode(new _m0.Reader(data)));
  }

  SearchEmployees(request: SearchEmployeesRequest): Promise<SearchEmployeesResponse> {
    const data = SearchEmployeesRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'SearchEmployees', data);
    return promise.then((data) => SearchEmployeesResponse.decode(new _m0.Reader(data)));
  }

  CreateCompanyEvent(request: CreateCompanyEventRequest): Promise<CreateCompanyEventResponse> {
    const data = CreateCompanyEventRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'CreateCompanyEvent', data);
    return promise.then((data) => CreateCompanyEventResponse.decode(new _m0.Reader(data)));
  }

  InviteAttendeesForCompanyEvent(
    request: InviteAttendeesForCompanyEventRequest,
  ): Promise<InviteAttendeesForCompanyEventResponse> {
    const data = InviteAttendeesForCompanyEventRequest.encode(request).finish();
    const promise = this.rpc.request(
      'peng_hqengine_contract.HQWeb',
      'InviteAttendeesForCompanyEvent',
      data,
    );
    return promise.then((data) =>
      InviteAttendeesForCompanyEventResponse.decode(new _m0.Reader(data)),
    );
  }

  GetCompanyEvent(request: GetCompanyEventRequest): Promise<GetCompanyEventResponse> {
    const data = GetCompanyEventRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetCompanyEvent', data);
    return promise.then((data) => GetCompanyEventResponse.decode(new _m0.Reader(data)));
  }

  GetCompanyEvents(request: GetCompanyEventsRequest): Promise<GetCompanyEventsResponse> {
    const data = GetCompanyEventsRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetCompanyEvents', data);
    return promise.then((data) => GetCompanyEventsResponse.decode(new _m0.Reader(data)));
  }

  CancelCompanyEvent(request: CancelCompanyEventRequest): Promise<CancelCompanyEventResponse> {
    const data = CancelCompanyEventRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'CancelCompanyEvent', data);
    return promise.then((data) => CancelCompanyEventResponse.decode(new _m0.Reader(data)));
  }

  AcknowledgeCompanyEvent(
    request: AcknowledgeCompanyEventRequest,
  ): Promise<AcknowledgeCompanyEventResponse> {
    const data = AcknowledgeCompanyEventRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'AcknowledgeCompanyEvent', data);
    return promise.then((data) => AcknowledgeCompanyEventResponse.decode(new _m0.Reader(data)));
  }

  GetAvailableLocations(
    request: GetAvailableLocationsRequest,
  ): Promise<GetAvailableLocationsResponse> {
    const data = GetAvailableLocationsRequest.encode(request).finish();
    const promise = this.rpc.request('peng_hqengine_contract.HQWeb', 'GetAvailableLocations', data);
    return promise.then((data) => GetAvailableLocationsResponse.decode(new _m0.Reader(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var globalThis: any = (() => {
  if (typeof globalThis !== 'undefined') return globalThis;
  if (typeof self !== 'undefined') return self;
  if (typeof window !== 'undefined') return window;
  if (typeof global !== 'undefined') return global;
  throw 'Unable to locate global object';
})();

const atob: (b64: string) => string =
  globalThis.atob || ((b64) => globalThis.Buffer.from(b64, 'base64').toString('binary'));
function bytesFromBase64(b64: string): Uint8Array {
  const bin = atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

const btoa: (bin: string) => string =
  globalThis.btoa || ((bin) => globalThis.Buffer.from(bin, 'binary').toString('base64'));
function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  for (const byte of arr) {
    bin.push(String.fromCharCode(byte));
  }
  return btoa(bin.join(''));
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;
export type DeepPartial<T> = T extends Builtin
  ? T
  : T extends Array<infer U>
  ? Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U>
  ? ReadonlyArray<DeepPartial<U>>
  : T extends {}
  ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(date: Date): Timestamp {
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = Number(t.seconds) * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
  if (o instanceof Date) {
    return o;
  } else if (typeof o === 'string') {
    return new Date(o);
  } else {
    return fromTimestamp(Timestamp.fromJSON(o));
  }
}

function longToString(long: Long) {
  return long.toString();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
