import {
  AuthResponse,
  AcknowledgeUserInboxMessageResponse,
  GetUserInfoResponse,
  GetTrainingStatusResponse,
  GetUserInboxMessagesResponse,
  GetVaccinationStatusResponse,
  GetHATTStatusResponse,
  IsBadgeExemptResponse,
} from '../../contract/hqengine_hqweb';
import { User, UserInboxMessage } from './index';
import { StatusType } from '../../contract/gitlab.zgtools.net/zillow/triforce/libs/go/common_contract/status';
import { HQEngineErrorResponse, GetErrorStatusType } from '../errorHelpers';

export const validateAuthResp = (resp: AuthResponse): HQEngineErrorResponse => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.user || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
  }

  return errResp;
};

export const validateGetUserInfoResp = (
  resp: GetUserInfoResponse,
): [User, boolean, boolean, boolean, User | undefined, HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.user || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [{} as User, false, false, false, undefined, errResp];
  }

  const canImpersonate = resp.permissions !== undefined && resp.permissions.canImpersonate;
  const canMassUpload = resp.permissions !== undefined && resp.permissions.canMassUpload;
  const canPlanZEvent = resp.permissions !== undefined && resp.permissions.canPlanCompanyEvent;

  return [
    resp.user,
    canImpersonate,
    canMassUpload,
    canPlanZEvent,
    resp.impersonator?.user,
    errResp,
  ];
};

export const validateGetTrainingStatusResp = (
  resp: GetTrainingStatusResponse,
): [boolean, HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.completed || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [false, errResp];
  }

  return [resp.completed, errResp];
};

export const validateGetUserInboxMessagesResp = (
  resp: GetUserInboxMessagesResponse,
): [UserInboxMessage[], HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [[] as UserInboxMessage[], errResp];
  }

  return [resp.inboxMessages, errResp];
};

export const validateAcknowledgeUserInboxMessageResp = (
  resp: AcknowledgeUserInboxMessageResponse,
): [HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [errResp];
  }

  return [errResp];
};

export const validateGetVaccinationStatusResp = (
  resp: GetVaccinationStatusResponse,
): [boolean, HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [false, errResp];
  }

  return [resp.approved, errResp];
};

export const validateGetHATTStatusResp = (
  resp: GetHATTStatusResponse,
): [boolean, HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || !resp.completed || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [false, errResp];
  }

  return [resp.completed, errResp];
};

export const validateIsBadgeExemptResp = (resp: IsBadgeExemptResponse): [boolean, HQEngineErrorResponse] => {
  const errResp: HQEngineErrorResponse = {
    code: StatusType.STATUS_TYPE_SUCCESS,
    message: '',
  };

  if (!resp || resp.status !== StatusType.STATUS_TYPE_SUCCESS) {
    errResp.code = GetErrorStatusType(resp.status);
    errResp.message = resp.message || 'Unknown error';
    return [false, errResp];
  }

  return [resp.isExempt, errResp];

};
