import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Toast } from '@zillow/constellation';
import { ExemptVisitContainerProps } from './ExemptVisitContainer';
import { MAX_BOOKING_DATE } from '../BookingContainer/BookingContainer';
import { Office, mockGetOfficesBlockedDates } from '../../store/office';
import { DateUtils } from '../../utilities/dateutils';
import { GoogleAnalyticsUtils } from '../../utilities/analyticsutils';
import { CookieUtils } from '../../utilities/cookieutils';
import { isWednesday } from 'date-fns';

const officeIdCookieName = 'lastSelectedOffice';

export const ExemptVisitContainerLogic = ({
  isLoaded,
  offices,
  reservations,
  getOfficesBlockedDates,
  acknowledgeUserInboxMessage,
  userInboxMessages,
  enqueueToast,
}: ExemptVisitContainerProps) => {
  const [isBlockedDatesLoaded, setIsBlockedDatesLoaded] = useState(false);
  const [fullyBookedDates, setFullyBookedDates] = useState([] as Date[]);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [isMobileBookingModalOpen, setIsMobileBookingModalOpen] = useState(false);
  const [mobileCTA, setMobileCTA] = useState<React.ReactNode>(null);
  const didMountFlag = useRef(false);
  const [officeSelected, setOfficeSelected] = useState<Office | undefined>(undefined);

  /** load blocked dates */
  useEffect(() => {
    if (!isBlockedDatesLoaded) {
      getOfficesBlockedDates(new Date(), MAX_BOOKING_DATE).finally(() =>
        setIsBlockedDatesLoaded(true),
      );
    }
  }, []);

  /** TF-1893 read cookie of last selected office
   * TF-1321 or fallback to use the office in earliest reservation
   */
  useEffect(() => {
    const setIndex = (idx: number) => {
      setSelectedIdx(idx);
      setOfficeSelected(offices[idx]);
    };
    if (isLoaded && offices.length > 0) {
      const officeIdCookie = CookieUtils.getCookie(officeIdCookieName);

      if (officeIdCookie) {
        const foundIndex = offices.findIndex((office) => officeIdCookie === office.id);
        const selectedIndex = foundIndex !== -1 ? foundIndex : 0;
        setIndex(selectedIndex);
      } else if (reservations.length > 0) {
        const sorted = reservations.sort((a, b) => {
          if (a.visitDate && b.visitDate) {
            const diff = a.visitDate.getTime() - b.visitDate.getTime();
            return diff === 0 ? parseInt(a.id) - parseInt(b.id) : diff;
          }
          return 0;
        });
        const foundIndex = offices.findIndex((office) => sorted[0]?.officeId === office.id);
        const nextIndex = foundIndex !== -1 ? foundIndex : 0;
        setIndex(nextIndex);
      } else {
        setIndex(0);
      }
    }
  }, [isLoaded, offices]);

  useEffect(() => {
    if (isLoaded && userInboxMessages.length > 0) {
      for (const msg of userInboxMessages) {
        const toast = (
          <Toast
            appearance="success"
            body={msg.messageText}
            onClose={() => handleToastClose(msg.id)}
          />
        );
        enqueueToast(toast, {
          duration: 0 /* must manually dismiss */,
        });
      }
    }
  }, [isLoaded, userInboxMessages]);

  const handleToastClose = useCallback(
    (messageId: string) => {
      acknowledgeUserInboxMessage(messageId);
    },
    [acknowledgeUserInboxMessage],
  );

  /** TF-1893 set a cookie of last selected office */
  useEffect(() => {
    const setLastSelectedOffice = (office: Office) => {
      document.cookie = `${officeIdCookieName}=${office.id}`;

    };
    if (didMountFlag.current && officeSelected) {
      setLastSelectedOffice(officeSelected);
    } else {
      didMountFlag.current = true;
    }
  }, [officeSelected]);

  const handleOfficeChange = useCallback(
    (officeIdx: number) => {
      setSelectedIdx(officeIdx);
      setOfficeSelected(offices[officeIdx]);
      GoogleAnalyticsUtils.SelectOffice(offices[officeIdx].name);
    },
    [offices, setSelectedIdx],
  );

  return {
    isBlockedDatesLoaded,
    fullyBookedDates,
    setFullyBookedDates,
    selectedIdx,
    handleOfficeChange,
    officeSelected,
    isMobileBookingModalOpen,
    setIsMobileBookingModalOpen,
    mobileCTA,
    setMobileCTA,
  };
};

export const MockExemptVisitContainerLogic = ({
  isLoaded,
  offices,
  reservations,
}: ExemptVisitContainerProps) => {
  const [isBlockedDatesLoaded, setIsBlockedDatesLoaded] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [fullyBookedDates, setFullyBookedDates] = useState([] as Date[]);
  const [isMobileBookingModalOpen, setIsMobileBookingModalOpen] = useState(false);
  const [mobileCTA, setMobileCTA] = useState<React.ReactNode>(null);
  const didMountFlag = useRef(false);
  const [officeSelected, setOfficeSelected] = useState<Office | undefined>(undefined);

  const dispatch = useDispatch();

  /** load blocked dates */
  useEffect(() => {
    setIsBlockedDatesLoaded(false);
    dispatch(mockGetOfficesBlockedDates());
    setIsBlockedDatesLoaded(true);
  }, []);

  /** load fully booked dates when selected office changes */
  useEffect(() => {
    const allDates = DateUtils.GetDatesBetween(new Date(), MAX_BOOKING_DATE);
    setFullyBookedDates(allDates.filter((d) => isWednesday(d)));
  }, [officeSelected]);

  /** TF-1893 read cookie of last selected office
   * TF-1321 or fallback to use the office in earliest reservation
   */
  useEffect(() => {
    if (isLoaded && offices.length > 0) {
      const officeIdCookie = CookieUtils.getCookie(officeIdCookieName);
      if (officeIdCookie) {
        const foundIndex = offices.findIndex((office) => officeIdCookie === office.id);
        const selectedIndex = foundIndex !== -1 ? foundIndex : 0;
        setSelectedIdx(selectedIndex);
        setOfficeSelected(offices[selectedIndex]);
      } else if (reservations.length > 0) {
        const sorted = reservations.sort((a, b) => {
          if (a.visitDate && b.visitDate) {
            const diff = a.visitDate.getTime() - b.visitDate.getTime();
            return diff === 0 ? parseInt(a.id) - parseInt(b.id) : diff;
          }
          return 0;
        });
        const foundIndex = offices.findIndex((office) => sorted[0]?.officeId === office.id);
        const nextIndex = foundIndex !== -1 ? foundIndex : 0;
        setSelectedIdx(nextIndex);
        setOfficeSelected(offices[nextIndex]);
      } else {
        setSelectedIdx(0);
        setOfficeSelected(offices[0]);
      }
    }
  }, [isLoaded, offices]);

  /** TF-1893 set a cookie of last selected office */
  useEffect(() => {
    const setLastSelectedOffice = (office: Office) => {
      document.cookie = `${officeIdCookieName}=${office.id}`;
    };
    if (didMountFlag.current && officeSelected) {
      setLastSelectedOffice(officeSelected);
    } else {
      didMountFlag.current = true;
    }
  }, [officeSelected]);

  const handleOfficeChange = useCallback(
    (officeIdx: number) => {
      setSelectedIdx(officeIdx);
    },
    [setSelectedIdx],
  );

  return {
    isBlockedDatesLoaded,
    fullyBookedDates,
    setFullyBookedDates,
    selectedIdx,
    handleOfficeChange,
    officeSelected,
    isMobileBookingModalOpen,
    setIsMobileBookingModalOpen,
    mobileCTA,
    setMobileCTA,
  };
};
