import { OfficeAnnouncementsProps } from "./OfficeAnnouncementsCard";

export const Announcements: OfficeAnnouncementsProps[] = [
    {
        announcement: "Denver Office is opening up on 02/10! Non-exempt employees will be able to start booking \
        on 02/03. All employees will be able to start using the office on 02/10.",
        expirationDate: new Date('February 10, 2025'),
        startDate: new Date('January 26, 2025')
    },
    {
        announcement: "Individual working spaces are available on a \
        first-come, first-served basis and cannot be reserved. For group and Zoom spaces, please \
        use Outlook for bookings. Currently, Zillow office visits are only available to employees.",
        expirationDate: new Date('December 31, 2025'),
        startDate: new Date('January 1, 2025')
    },
]
