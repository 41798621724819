import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { mediaBreakpointMixin, PageContent, Flex, Spacer } from '@zillow/constellation';
import { HomeContainerLogic, MockHomeContainerLogic } from './HomeContainer.hooks';
import OfficeVisitContainer from '../OfficeVisitContainer';
import ExemptVisitContainer from '../ExemptVisitContainer';
import UpcomingVisitsContainer from '../UpcomingVisitsContainer';
import { QuickLinksCard } from '../../components/QuickLinksCard';
// import { ZRetreatCard } from '../../components/ZRetreatCard';
import { Loader } from '../../components/Loader';
import { RootState } from '../../store';
import { getSystemStatus, selectHasDependencyError } from '../../store/systemHealth';
import { getUserTrainingStatus, getUserInboxMessages, getUserHATTStatus, isBadgeExempt, selectUserInfo } from '../../store/user';
import { getOffices, getReservations, getOfficesFloors } from '../../store/office';
import { getZEvents, ZEventListType } from '../../store/zevent';
import { IsMockContext } from '../../middleware/auth';
import { IsBookingInProgressProvider } from './IsBookingInProgressContext';
import { OfficeAnnouncementsCard } from '../../components/OfficeAnnouncementsCard';

export interface HomeContainerProps {
  /** is trained in ZGU */
  getUserTrainingStatus: () => Promise<void>;
  /** user inbox message (vaccine approved status changed ...) */
  getUserInboxMessages: () => Promise<void>;
  /** has done HATT */
  getUserHATTStatus: () => Promise<void>;
  /** get offices request */
  getOffices: (isInternal: boolean) => Promise<void>;
  /** get upcoming visits */
  getReservations: (startDate: Date | undefined, endDate: Date | undefined) => Promise<void>;
  /** get offices floors request */
  getOfficesFloors: (isInternal: boolean) => Promise<void>;
  /** is dependency down */
  hasDependencyError: boolean;
  /** get dependencies status */
  getSystemStatus: () => Promise<void>;
  /** get zretreat events */
  getZEvents: (startDate: Date, endDate: Date, listType: ZEventListType) => Promise<void>;
  /** is badge exempt */
  isBadgeExempt:(userId : string) => Promise<boolean>;
}

const DesktopOnly = css`
  @media ${mediaBreakpointMixin('lg')} {
    display: block;
  }
  display: none;
`;

const MainCardSection = styled(Flex)`
  flex: 10 1 820px;
`;

const smallCardsSize = '385px';

const RightCardSection = styled(Flex)`
  flex: 0 2 ${smallCardsSize};
`;

const QuickLinksCardWrapper = styled(Flex)`
  flex: 1 0 290px;
  ${DesktopOnly}
`;

/* const ZRetreatCardWrapper = styled(Flex)`
   flex: 1 0 180px;
`;*/

const UpcomingVisitsWrapper = styled(Flex)`
  flex: 1 0 ${smallCardsSize};
`;

const HomeContainer: React.FC<HomeContainerProps> = (props: HomeContainerProps) => {
  const isMock = React.useContext(IsMockContext);
  const { hasDependencyError, isBadgeExempt } = props;
  const { isLoaded, hasLoadingError } = isMock
    ? MockHomeContainerLogic()
    : HomeContainerLogic(props);

  const [exempt, setExemptUser] = useState(false);
  const userID = useSelector((state: RootState) => selectUserInfo(state).id);

  const isExemptUser = (): boolean => {
      isBadgeExempt(userID)
    .then((isExempt) => {
      setExemptUser(isExempt);
      return isExempt;
    }).catch(() => {
      setExemptUser(false);
      return false;
    });

    return false;
  };

  return (
    <PageContent>
      <Loader loading={!isLoaded} centerOnPage={true}>
        <IsBookingInProgressProvider>
          {isLoaded && (
            <Flex display="flex" marginTop="lg" flexDirection={{ default: 'column', lg: 'row' }}>
              <MainCardSection>
                {isExemptUser()}
                { exempt ? (
                  <ExemptVisitContainer
                    isLoaded={isLoaded}
                    hasLoadingError={hasLoadingError}
                    hasDependencyError={hasDependencyError}
                  />
                ) : (
                  <OfficeVisitContainer
                    isLoaded={isLoaded}
                    hasLoadingError={hasLoadingError}
                    hasDependencyError={hasDependencyError}
                  />
                )}
              </MainCardSection>
              <Spacer paddingRight={{ lg: 'md' }} paddingTop={{ md_lte: 'md' }} />
              <RightCardSection display="flex" flexDirection="column">
                <QuickLinksCardWrapper>
                  <QuickLinksCard />
                </QuickLinksCardWrapper>
                {/* <Spacer paddingTop={{ lg: 'md' }} /> */}
                {/* <ZRetreatCardWrapper>
                  <ZRetreatCard />
                </ZRetreatCardWrapper> */}
                <Spacer paddingTop="md" />
                <UpcomingVisitsWrapper>
                  {exempt ? (
                    <OfficeAnnouncementsCard/>
                    ):(
                      <UpcomingVisitsContainer isLoaded={isLoaded} hasLoadingError={hasLoadingError} />
                    )}
                </UpcomingVisitsWrapper>
              </RightCardSection>
            </Flex>
          )}
        </IsBookingInProgressProvider>
      </Loader>
    </PageContent>
  );
};

const mapStateToProps = (state: RootState) => ({
  hasDependencyError: selectHasDependencyError(state),
});

const mapDispatchToProps = {
  getUserTrainingStatus: getUserTrainingStatus,
  getUserInboxMessages: getUserInboxMessages,
  getUserHATTStatus: getUserHATTStatus,
  isBadgeExempt: isBadgeExempt,
  getOffices: getOffices,
  getReservations: getReservations,
  getOfficesFloors: getOfficesFloors,
  getSystemStatus: getSystemStatus,
  getZEvents: getZEvents,
};

type StateToPropsType = ReturnType<typeof mapStateToProps>;
type DispatchToPropsType = typeof mapDispatchToProps;

export default connect<StateToPropsType, DispatchToPropsType, unknown, RootState>(
  mapStateToProps,
  mapDispatchToProps,
)(HomeContainer);
